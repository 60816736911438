import { FC, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useDi from 'common/lib/DependencyInjection/useDi';

export const LOCATION_CHANGE = 'LOCATION_CHANGE';

const LocationChangeObserver: FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const di = useDi();
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      di.get('seo.meta').reset();
      dispatch({
        type: LOCATION_CHANGE,
      });
    }
    mounted.current = true;
  }, [dispatch, location, di]);
  return null;
};

export default LocationChangeObserver;
