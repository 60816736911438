const DataLayerSnippet = (initialEvents: Object[]): string =>
  'window.dataLayer = window.dataLayer || [];' +
  initialEvents?.map((event) => `window.dataLayer.push(${JSON.stringify(event)});`).join(';');

const getGTMScript = (id: string) => {
  const params = '';

  return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl${params};f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}');`;
};

export const createDataLayerScript = (initialEvents: Object[]) => {
  const dataLayerScript = document.createElement('script');
  dataLayerScript.innerHTML = DataLayerSnippet(initialEvents);

  return dataLayerScript;
};

export const createGTMSnippet = (id: string) => {
  const script = document.createElement('script');
  script.id = 'gtm-snippet';
  script.innerHTML = getGTMScript(id);

  return script;
};
