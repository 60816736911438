import { ICookieService } from 'common/lib/Cookies/types';
import DependencyInjectionContainer, {
  FactoryWithIdentifier,
  ServiceKey,
} from 'common/lib/DependencyInjection/DependencyInjectionContainer';
import { IFetchService } from 'common/lib/Fetch/types';
import ILocationService from 'common/lib/Routing/services/LocationService';
import { config } from 'common/util/configHandler';
import { AppConfiguration, Locale } from 'common/util/configTypes';

import { ReactQueryClient } from 'common/modules/react-query/types';

import { IApplicationService } from 'common/modules/application/services/ApplicationService';
import { IAuthHandlerService } from 'common/modules/auth/services/AuthHandlerService';
import { IAuthService } from 'common/modules/auth/services/AuthService';
import { IUserActionService } from 'common/modules/auth/services/UserActionService';
import { IActivateBookmarkService } from 'common/modules/bookmark/services/ActivateBookmarkService';
import { IBookmarkedEntityFetchService } from 'common/modules/bookmark/services/bookmarkedEntityFetchService';
import { ICompanySearchService } from 'common/modules/company-search/services/CompanySearchService';
import {
  createDataTrackingService,
  IDataTrackingService,
} from 'common/modules/data-tracking/services/DataTrackingService';
import { ICompanyVacanciesFetchService } from 'common/modules/detail-company/services/FetchCompanyVacanciesService';
import { IDocumentService } from 'common/modules/document/services/DocumentService';
import { IInfoBlockService } from 'common/modules/info-block/services/InfoBlockService';
import { IInfoLinkService } from 'common/modules/info-links/services/InfoLinkService';
import { ISearchProfileSaveService } from 'common/modules/jobalert/services/JobAlertService';
import { ISearchProfileFetchService } from 'common/modules/jobalert/services/SearchProfileFetchService';
import { IOnboardingService } from 'common/modules/onboarding/services/OnboardingService';
import { IProfileService } from 'common/modules/profile/services/ProfileService';
import { ISalaryService } from 'common/modules/salary-acquisition/service/salaryService';
import { IVacancySearchService } from 'common/modules/search-vacancy/services/VacancySearchService';
import { IVacancySearchServiceNew } from 'common/modules/search-vacancy/services/VacancySearchServiceNew';
import { ISeoService } from 'common/modules/seo/services/SeoService';
import { ITealiumSimpleABTestService } from 'common/modules/simple-ab/services/TealiumSimpleABTestService';
import { ITealiumService, TealiumServiceFactory } from 'common/modules/tealium/services/TealiumService';
import {
  createTrackingCollectorService,
  ITrackingCollectorService,
} from 'common/modules/tracking/services/TrackingCollectorService';
import { ITranslationService } from 'common/modules/translation/services/TranslationService';
import { IReportAdService } from 'common/modules/vacancy/services/ReportAdService';
import { IVacancyFetchService } from 'common/modules/vacancy/services/VacancyFetchService';
import { IReduxService } from 'common/stores/global';

export interface ServiceMap {
  'application.dynamic.service': IApplicationService;
  'application.email.service': IApplicationService;
  'auth.handler': IAuthHandlerService;
  'auth.service': IAuthService;
  'auth.userAction': IUserActionService;
  'bookmarks.activate': IActivateBookmarkService;
  'bookmarks.fetch.bookmarkByEntities': IBookmarkedEntityFetchService;
  'company.companySearch': ICompanySearchService;
  'company.detail.fetchVacancies': ICompanyVacanciesFetchService;
  'core.config': AppConfiguration;
  'core.cookie': ICookieService;
  'core.dataTracking': IDataTrackingService;
  'core.fetch': IFetchService;
  'core.locale': Locale;
  'core.location': ILocationService;
  'core.redux': IReduxService;
  'core.tealium': ITealiumService;
  'core.trackingCollector': ITrackingCollectorService;
  'core.translation': ITranslationService;
  'document.service': IDocumentService;
  'onboarding.service': IOnboardingService;
  'profile.service': IProfileService;
  'query.client': ReactQueryClient;
  'search.infoBlock': IInfoBlockService;
  'search.infoLinks': IInfoLinkService;
  'searchProfile.fetch': ISearchProfileFetchService;
  'searchProfile.save': ISearchProfileSaveService;
  'seo.meta': ISeoService;
  'tracking.abTest': ITealiumSimpleABTestService;
  'vacancy.fetchVacancy': IVacancyFetchService;
  'vacancy.reportAd': IReportAdService;
  'vacancy.vacancySearch': IVacancySearchService;
  'vacancy.vacancySearchNew': IVacancySearchServiceNew;
  'salary.service': ISalaryService;
}

export type DependencyInjection = DependencyInjectionContainer<ServiceMap>;
export type DiFactory<T extends ServiceKey<ServiceMap>> = FactoryWithIdentifier<ServiceMap, T>;

export const getDiInstance = (): DependencyInjection => {
  const di: DependencyInjection = new DependencyInjectionContainer<ServiceMap>();
  di.set('core.config', () => config);

  di.set('core.dataTracking', (di) =>
    createDataTrackingService(
      di.get('core.config'),
      di.get('core.fetch'),
      di.get('core.redux'),
      di.get('core.location'),
      di.get('core.cookie'),
      di.get('core.locale'),
      di.get('query.client')
    )
  );

  di.set('core.trackingCollector', createTrackingCollectorService);

  di.set('core.tealium', TealiumServiceFactory);
  return di;
};
