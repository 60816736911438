import { PayloadAction } from 'common/lib/Actions/types';
import { LOCALIZATION } from 'common/lib/Localization/localizationActions';
import createReducer from 'common/util/createReducer';

export type LocalizationState = 'CH' | string | null;

const initialState: LocalizationState = null;

export default createReducer(initialState, {
  [LOCALIZATION.SET](_state: LocalizationState, action: PayloadAction<LocalizationState>): LocalizationState {
    return action.payload;
  },
});
