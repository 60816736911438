import { FetchResult } from 'common/lib/Fetch/types';

import { Result } from 'common/types/general';

const throwOnFailure = <S = unknown, F = unknown>(response: FetchResult<S, F>) => {
  if (response.ok) return response;
  throw response;
};

export const throwOnFailureResult = <S = unknown, F = unknown>(response: Result<S, F>): S => {
  if (response.ok) return response.data;
  throw response.error;
};

export default throwOnFailure;
