import type { Extras, Primitive, Scope } from '@sentry/types';
import sha256 from 'sha256';

import { config } from 'common/util/configHandler';
import { AppConfiguration, Tenant } from 'common/util/configTypes';

import type { ClientClassification } from 'common/modules/bot-detect/types';

export type MetaData = {
  tags?: Record<string, Primitive>;
  extras?: Extras;
};

type Configuration = {
  tenant: Tenant;
  environment: string;
  origin?: 'server' | 'client';
  sessionId?: string;
  clientClassification?: ClientClassification;
};

const isSentryAvailable = () => !!config.SENTRY_PUBLIC_KEY && typeof Sentry !== 'undefined';

const logException = (error: Error, meta: MetaData = {}) => {
  Sentry.withScope((scope: Scope) => {
    const { tags = {}, extras = {} } = meta;

    Object.keys(tags).forEach((key) => scope.setTag(key, tags[key]));
    Object.keys(extras).forEach((key) => scope.setExtra(key, extras[key]));

    Sentry.captureException(error);
  });
};

export const logViaSentry = isSentryAvailable() ? logException : () => null;

export const initSentry = (
  config: AppConfiguration,
  { clientClassification, environment, origin, sessionId, tenant }: Configuration
) => {
  if (!isSentryAvailable()) return;

  Sentry.init({
    ...config.SENTRY_OPTIONS,
    environment,
  });

  Sentry.configureScope((scope: Scope) => {
    scope.setTag('tenant', tenant);
    origin && scope.setTag('origin', origin);
    !!sessionId && scope.setUser({ id: sha256(sessionId) });
    !!clientClassification && scope.setTag('client.classification', clientClassification);
  });

  Sentry.forceLoad?.();
};
