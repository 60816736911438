import { combineReducers } from 'redux';

import minimalAvatarReducer, {
  State as MinimalAvatarState,
} from 'common/modules/minimal-user/reducers/minimalAvatarReducer';
import statsReducer, { State as StatsState } from 'common/modules/minimal-user/reducers/statsReducer';

export interface State {
  minimalAvatar: MinimalAvatarState;
  stats: StatsState;
}

export default combineReducers<State>({
  minimalAvatar: minimalAvatarReducer,
  stats: statsReducer,
});
