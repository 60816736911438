import { PayloadAction } from 'common/lib/Actions/types';
import { PREVENT } from 'common/lib/SideEffects/prevent/actionTypes';
import createReducer from 'common/util/createReducer';

import { PreventConfiguration } from 'common/lib/SideEffects/prevent/actions/preventActions';

export type State = PreventConfiguration;

const initialState: State = {};

export default createReducer<State>(initialState, {
  [PREVENT.SET](state: State, { payload }: PayloadAction<PreventConfiguration>) {
    return { ...state, ...payload };
  },
  [PREVENT.RESET]() {
    return initialState;
  },
});
