import createReducer from 'common/util/createReducer';

import { LOCATION_CHANGE } from 'common/lib/Routing/components/LocationChangeObserver';

import { BreadCrumb } from 'common/modules/breadcrumbs/types';

import { SEO } from 'common/modules/seo/actionTypes';

export type State = BreadCrumb[];

const initialState: State = [];

export default createReducer<State>(initialState, {
  [SEO.SET_BREADCRUMBS](_state, action) {
    return action.payload;
  },

  [LOCATION_CHANGE]() {
    return [];
  },
});
