export const TRACK_GTM = '@LIB/TRACKING.TRACK_GTM';

interface EventDetails {
  category: string;
  action: string;
  label?: string | number;
}

export interface Payload {
  event: string;
}

export interface DetailedPayload extends Payload {
  event_details: EventDetails;
}

export interface TrackingSourcePayload extends Payload {
  trackingSource?: string;
}

interface SourcePayload extends Payload {
  source: string;
}

interface UserPayload extends Payload {
  userUUID: string | null;
}

export type TrackingPayload = Payload | DetailedPayload | SourcePayload | TrackingSourcePayload | UserPayload;

export const trackGtm = (payload: TrackingPayload | string) => ({
  type: TRACK_GTM,
  payload,
});
