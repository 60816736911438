import createReducer from 'common/util/createReducer';

import { SEO } from 'common/modules/seo/actionTypes';

type State = {
  isLoading: boolean;
  hasError: boolean;
  list: {
    company_name?: string;
    company_slug: string;
    job_id: string;
    place?: string;
    publication_date: string;
    title: string;
  }[];
};

const initialState = {
  isLoading: false,
  hasError: false,
  list: [],
};

export default createReducer<State>(initialState, {
  [SEO.NEW_JOBS.FETCH.REQUEST](state) {
    return {
      ...state,
      isLoading: true,
      hasError: false,
    };
  },

  [SEO.NEW_JOBS.FETCH.SUCCESS](state, action) {
    return {
      ...state,
      isLoading: false,
      hasError: false,
      list: action.payload.documents,
    };
  },

  [SEO.NEW_JOBS.FETCH.FAILURE](state) {
    return {
      ...state,
      isLoading: false,
      hasError: true,
    };
  },
});
