import { styled } from 'panda/jsx';

export const Tag = styled('span', {
  base: {
    whiteSpace: 'nowrap',
    alignItems: 'center',
    border: '[1px solid transparent]',
    borderRadius: 'r16',
    display: 'inline-flex',
    lineHeight: 1.66,
    px: 's8',
  },
  variants: {
    tagColor: {
      blue: { bg: 'blue.10', color: 'blue.80' },
      brand: { bg: 'brand.10', color: 'brand.80' },
      green: { bg: 'green.10', color: 'green.80' },
      gray: { bg: 'gray.10', color: 'gray.80' },
      purple: { bg: 'purple.10', color: 'purple.80' },
      red: { bg: 'red.10', color: 'red.80' },
      white: { bg: 'white', color: 'gray.70' },
      yellow: { bg: 'yellow.10', color: 'yellow.80' },
    },
    tagSize: {
      sm: {
        fontSize: 'f12',
      },
      md: {
        fontSize: 'f14',
      },
    },
  },
  defaultVariants: {
    tagColor: 'brand',
    tagSize: 'md',
  },
});
