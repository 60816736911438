import { combineReducers } from 'redux';

import notLoggedInUserReducer, {
  State as NotLoggedInUserReducerState,
} from 'common/modules/auth/reducers/notLoggedInUserReducer';

export type State = {
  notLoggedInUser: NotLoggedInUserReducerState;
};

export default combineReducers<State>({
  notLoggedInUser: notLoggedInUserReducer,
});
