/**
 * This middleware is a temporary solution to move from redux mess to simple handlers like the cookie handler.
 * It provides an action to add the cookie handler on to an action. Really only for temporary reason until a fetchHandler
 * is in place.
 */
import { Middleware } from 'redux';

import { type GeneralAction } from 'common/lib/Actions/types';
import { ICookieService } from 'common/lib/Cookies/types';
import { Locale } from 'common/util/configTypes';

import { IReduxService } from 'common/stores/global';

const WITH_COOKIE_HANDLER = 'WITH_COOKIE_HANDLER';

/**
 * @deprecated
 * @param action
 */
export const withCookieHandlerAction = (
  action: (cookieHandler: ICookieService, locale: Locale) => GeneralAction
): GeneralAction => ({
  type: WITH_COOKIE_HANDLER,
  action,
});

/**
 * @deprecated
 * @param cookieHandler
 */
const cookieHandlerMiddleware = (cookieHandler: ICookieService): Middleware =>
  (({ dispatch }: IReduxService) =>
    (next: GeneralAction) =>
    (action: GeneralAction) => {
      if (action.type !== WITH_COOKIE_HANDLER) {
        return next(action);
      }
      return dispatch(action.action(cookieHandler));
    }) as unknown as Middleware;

export default cookieHandlerMiddleware;
