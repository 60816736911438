import isPromise from 'is-promise';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';

import { MultiAction } from 'common/lib/Actions/types';

import GlobalState from 'common/stores/global';

/**
 * Dispatch multiple actions from one action creator.
 */
export default function multi({ dispatch }: MiddlewareAPI<any, GlobalState>) {
  return (next: Dispatch<AnyAction>) => (action: MultiAction) => {
    if (!Array.isArray(action)) {
      return next(action);
    }

    return Promise.all(action.filter(Boolean).map(dispatch).filter(isPromise));
  };
}
