import { createContext, FC, PropsWithChildren, useContext } from 'react';

import { useQueryParam } from 'common/lib/Routing/hooks/useQuery';

type Props = {
  value: string;
  enabled?: boolean;
  overwrite?: boolean;
  overwriteUrl?: boolean;
};

const TrackingSourceContext = createContext<string | undefined>(undefined);

export const TrackingSourceProvider: FC<PropsWithChildren<Props>> = ({
  children,
  value,
  enabled = true,
  overwrite = false,
  overwriteUrl = false,
}) => {
  const context = useContext(TrackingSourceContext);
  const urlSource = useQueryParam('source');

  const parts: string[] = [];
  if (!!context && !overwrite && !overwriteUrl) {
    parts.push(context);
  }
  if (enabled) {
    parts.push(value);
  }

  const source = !!urlSource && !overwriteUrl ? urlSource : parts.join('_');

  return <TrackingSourceContext.Provider value={source}>{children}</TrackingSourceContext.Provider>;
};

export default TrackingSourceContext;
