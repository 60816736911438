import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import { localFlagReducer } from 'common/modules/local-flags/reducers/localFlagReducer';

const config = {
  key: 'localFlags',
  storage,
};

const localFlagPersistReducer = persistReducer(config, localFlagReducer);

export default localFlagPersistReducer;
