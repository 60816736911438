import { ICookieService } from 'common/lib/Cookies/types';
import ILocationService from 'common/lib/Routing/services/LocationService';
import { redirect } from 'common/lib/UniversalTools/serverActions';
import { getClosestFeature } from 'common/modules/tealium/utils/clickUtils';
import { Locale } from 'common/util/configTypes';

import { open } from 'common/modules/modal/actions/modalActions';

import { LoginNavigations } from 'common/modules/auth/types';

import { assembleLoginLink } from 'common/modules/auth/utils';
import { ITealiumService } from 'common/modules/tealium/services/TealiumService';
import { IReduxService } from 'common/stores/global';

export interface IAuthService {
  getLoginLink(loginNavigations: LoginNavigations): string;
  navigateToLogin(loginNavigations: LoginNavigations, trigger?: HTMLElement): void;
  trackUserAuthStart(loginNavigations: LoginNavigations, loginLink: string, trigger?: HTMLElement): void;
}

const eventName = 'user_auth_start';

export class AuthService implements IAuthService {
  private cookieService: ICookieService;
  private locale: Locale;
  private location: ILocationService;
  private reduxService: IReduxService;
  private tealiumService: ITealiumService;

  constructor(
    cookieService: ICookieService,
    locale: Locale,
    location: ILocationService,
    reduxService: IReduxService,
    tealiumService: ITealiumService
  ) {
    this.cookieService = cookieService;
    this.locale = locale;
    this.location = location;
    this.reduxService = reduxService;
    this.tealiumService = tealiumService;
  }

  getLoginLink = ({ authSource, cancelTo, successTo }: LoginNavigations): string =>
    assembleLoginLink({
      authSource,
      cancelTo,
      isMobile: this.reduxService.getState().mobileDetect.isMobile,
      locale: this.locale,
      location: this.location.getLocation(),
      successTo,
    });

  navigateToLogin = (loginNavigations: LoginNavigations, trigger?: HTMLElement | undefined): void => {
    const { getState, dispatch } = this.reduxService;

    if (getState().featureFlags.includes('onelog-outage') && !this.cookieService.get('disable_onelog_outage')) {
      dispatch(open({ key: 'onelog-outage' }));
      return;
    }

    const loginLink = this.getLoginLink(loginNavigations);

    if (typeof window !== 'undefined') {
      this.trackUserAuthStart(loginNavigations, loginLink, trigger);
      return window.location.assign(loginLink);
    }

    dispatch(redirect({ pathname: loginLink }, 301));
  };

  trackUserAuthStart = (
    loginNavigations: LoginNavigations,
    loginLink: string,
    trigger?: HTMLElement | undefined
  ): void => {
    this.tealiumService.track({
      event_action: eventName,
      event_name: eventName,
      user_auth_source: loginNavigations.authSource,
      ...(trigger
        ? {
            event_target_url_full: loginLink,
            event_type: 'external_link',
            event_link_text: trigger.textContent || trigger.getAttribute('title') || '',
            event_component_type: getClosestFeature(trigger),
          }
        : {}),
    });
  };
}
