import useDi from 'common/lib/DependencyInjection/useDi';
import { TFunction } from 'common/modules/translation/lib/PolyglotCopy';

const useTranslation = (): TFunction => {
  const di = useDi();
  const translationService = di.get('core.translation');
  return translationService.t;
};

export default useTranslation;
