export const SEO = {
  NEW_JOBS: {
    FETCH: {
      REQUEST: 'NEW_JOBS.FETCH.REQUEST',
      SUCCESS: 'NEW_JOBS.FETCH.SUCCESS',
      FAILURE: 'NEW_JOBS.FETCH.FAILURE',
    },
  },
  SET_BREADCRUMBS: 'SEO.SET_BREADCRUMBS',
};
