export const LOCAL_FLAG = {
  SET: 'LOCAL_FLAG.SET',
  UNSET: 'LOCAL_FLAG.UNSET',
};

//TODO: FIAP-519 fix this any... should be replaced with a boolean | string, refactor localFlagsSelectors wich then will have some ts errors
export type Flags = Record<string, any>;

export interface NameSpaces {
  [key: string]: Flags;
}
