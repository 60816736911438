import { combineReducers } from 'redux';

import seoBreadcrumbReducer, { State as BreadcrumbState } from 'common/modules/seo/reducers/seoBreadcrumbReducer';
import seoNewJobsReducer from 'common/modules/seo/reducers/seoNewJobsReducer';

export interface SeoState {
  breadcrumbs: BreadcrumbState;
  jobs: {
    isLoading: boolean;
    hasError: boolean;
    list: {
      company_name?: string;
      company_slug: string;
      job_id: string;
      place?: string;
      publication_date: string;
      title: string;
    }[];
  };
  meta: Record<string, never>;
}

const seoReducer = combineReducers<SeoState>({
  breadcrumbs: seoBreadcrumbReducer,
  jobs: seoNewJobsReducer,
  meta: () => ({}),
});

export default seoReducer;
