import userFlagsNormalizer from 'common/modules/user-flags/normalizers/userFlagsNormalizer';

import { ApiItem, User } from 'common/modules/auth/types';

export default function userNormalizer(apiItem: ApiItem): User {
  return {
    audience: apiItem.audience || 'awareness',
    avatarUrl: apiItem.profile_picture_url,
    createdAt: apiItem.created_at,
    email: apiItem.email,
    emailVerified: apiItem.email_address_verified,
    emailValid: apiItem.emails_deliverable,
    firstName: apiItem.first_name,
    language: apiItem.language,
    lastName: apiItem.last_name,
    uuid: apiItem.id,
    flags: userFlagsNormalizer(apiItem.user_flags),
  };
}
