import ILocationService from 'common/lib/Routing/services/LocationService';
import {
  appendQueryParamToUrl,
  getFullPath,
  getFullUrl,
  removeQueryParametersStartingWith,
} from 'common/lib/Routing/utils/pathUtils';
import type { UserAuthSource } from 'common/modules/tealium/utils/tealiumEvents';
import { config, routes, tenant } from 'common/util/configHandler';
import { Locale, Tenant } from 'common/util/configTypes';

import type { InvitationOptions, LoginLinkOptions } from 'common/modules/auth/types';

export const assembleInvitationUris = ({ authSource, cancelTo, locale, successTo }: InvitationOptions) => {
  const baseSuccessUri = successTo ? getFullPath(successTo, locale) : getFullPath({ route: routes.HOME }, locale);

  return {
    successUri: getFullUrl(appendQueryParamToUrl(baseSuccessUri, `auth_source=${authSource}`), false),
    cancelUri: getFullUrl(cancelTo ? getFullPath(cancelTo, locale) : baseSuccessUri, false),
  };
};

export const assembleLoginLink = ({
  authSource,
  cancelTo,
  isMobile,
  locale,
  location,
  successTo,
}: LoginLinkOptions) => {
  const clientApi = typeof config.API === 'string' ? config.API : config.API.CLIENT;
  const ignoredQueryParams = ['utm_', 'fbclid', 'aw_', 'search'];
  const maxUrlLength = 2048;

  const baseSuccessUri = successTo
    ? getFullPath(successTo, locale)
    : `${location.pathname}${removeQueryParametersStartingWith(location.search, ignoredQueryParams)}`;

  const successUri = getFullUrl(appendQueryParamToUrl(baseSuccessUri, `auth_source=${authSource}`));

  const cancelUri = getFullUrl(cancelTo ? getFullPath(cancelTo, locale) : baseSuccessUri);

  const source = isMobile ? `source=${tenant.get()}_ch_mobile` : `source=${tenant.get()}_ch_desktop`;

  //onelog for jobup is only available in en/fr this redirects login calls from DE jobup routes to the en-onelog page
  const loginLink = `${clientApi}user/onelog/issue-authorization-state?${source}&success_uri=${successUri}&cancel_uri=${cancelUri}&lang=${
    tenant.get() === Tenant.JOBUP && locale === Locale.DE ? Locale.EN : locale
  }`;

  return loginLink.length >= maxUrlLength
    ? `${clientApi}user/onelog/issue-authorization-state?${source}&success_uri=${successUri}&cancel_uri=${getFullUrl(
        getFullPath({ route: routes.HOME }, locale)
      )}&lang=${locale}`
    : loginLink;
};

export const getAuthSourceFromLocation = ({ getRouteKey }: ILocationService): UserAuthSource => {
  const routeKey = getRouteKey();

  if (routeKey === 'LANDINGPAGE_RICO') {
    return 'rico_landingpage';
  }

  if (routeKey === 'USER_SETTINGS') {
    return 'email';
  }

  return 'not_set';
};
