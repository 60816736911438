import { FC, useEffect } from 'react';

import { useLocalization } from 'common/lib/Localization/useLocalization';
import useGoogleTagManager from 'common/lib/Tracking/GoogleTagManager/useGoogleTagManager';

import useConsent from 'common/lib/Cookies/hooks/useConsent';

const InitCookieConsent: FC = () => {
  const { getHasConsent, setAllConsent } = useConsent();
  const localization = useLocalization();
  useGoogleTagManager();

  useEffect(() => {
    localization === 'CH' && !getHasConsent() && setAllConsent();
  }, [getHasConsent, localization, setAllConsent]);

  return null;
};

export default InitCookieConsent;
