export const MINIMAL = {
  CANDIDATE: {
    FETCH: {
      REQUEST: 'PROFILE.CANDIDATE.FETCH.REQUEST',
      SUCCESS: 'PROFILE.CANDIDATE.FETCH.SUCCESS',
      FAILURE: 'PROFILE.CANDIDATE.FETCH.FAILURE',
    },
    UPDATE: {
      REQUEST: 'PROFILE.CANDIDATE.UPDATE.REQUEST',
      SUCCESS: 'PROFILE.CANDIDATE.UPDATE.SUCCESS',
      FAILURE: 'PROFILE.CANDIDATE.UPDATE.FAILURE',
    },
  },
  PICTURE: {
    FETCH: {
      REQUEST: 'PROFILE.PICTURE.FETCH.REQUEST',
      SUCCESS: 'PROFILE.PICTURE.FETCH.SUCCESS',
      FAILURE: 'PROFILE.PICTURE.FETCH.FAILURE',
    },
    UPDATE: {
      REQUEST: 'PROFILE.PICTURE.UPDATE.REQUEST',
      SUCCESS: 'PROFILE.PICTURE.UPDATE.SUCCESS',
      FAILURE: 'PROFILE.PICTURE.UPDATE.FAILURE',
    },
    DELETE: {
      REQUEST: 'PROFILE.PICTURE.DELETE.REQUEST',
      SUCCESS: 'PROFILE.PICTURE.DELETE.SUCCESS',
      FAILURE: 'PROFILE.PICTURE.DELETE.FAILURE',
    },
  },
  SEARCHPROFILE: {
    STATS: {
      SUCCESS: 'SEARCHPROFILE.STATS.SUCCESS',
    },
  },
};
