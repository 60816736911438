import { FC } from 'react';

import { Icon, IconProps } from 'common/ui/base';

const IconAlertWarning: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      d="M0.865996424,15.9992496 C0.552823676,16.0129187 0.260932781,15.8388951 0.120562135,15.554826 C-0.0401873782,15.2809415 -0.0401873782,14.9398633 0.120562135,14.6659788 L8.23264117,0.4444236 C8.39312879,0.148437482 8.64920739,0 9,0 C9.35079261,0 9.60687121,0.148437482 9.76735883,0.4444236 L17.8794379,14.6659788 C18.0401874,14.9398633 18.0401874,15.2809415 17.8794379,15.554826 C17.7390672,15.8388951 17.4471763,16.0129187 17.1340036,15.9992496 L0.865996424,15.9992496 Z M9,6.2219304 C8.76605043,6.2160001 8.54008214,6.30831803 8.37558916,6.47702954 C8.20867214,6.64387063 8.11725885,6.87324403 8.12301848,7.1107776 L8.12301848,9.7773192 C8.12301848,10.028863 8.20720871,10.2395197 8.37558916,10.4101784 C8.53981401,10.5793968 8.76588199,10.6720773 9,10.6661664 C9.23436261,10.672004 9.46067398,10.5793538 9.62528782,10.4101784 C9.79174712,10.2434598 9.88283265,10.0144341 9.87698152,9.7773192 L9.87698152,7.1107776 C9.88276144,6.87341427 9.79170545,6.6441413 9.62528782,6.47702954 C9.46040692,6.30836026 9.23419464,6.21607227 9,6.2219304 L9,6.2219304 Z M9,13.332708 C9.23436261,13.3385456 9.46067398,13.2458954 9.62528782,13.07672 C9.79174712,12.9100014 9.88283265,12.6809757 9.87698152,12.4438608 C9.88283265,12.2067459 9.79174712,11.9777202 9.62528782,11.8110016 C9.46067398,11.6418262 9.23436261,11.549176 9,11.5550136 C8.76588199,11.5491027 8.53981401,11.6417832 8.37558916,11.8110016 C8.20862972,11.9774484 8.11718649,12.2065751 8.12301848,12.4438608 C8.12301848,12.6954046 8.20720871,12.9060613 8.37558916,13.07672 C8.53981401,13.2459384 8.76588199,13.3386189 9,13.332708 L9,13.332708 Z M2.37878954,14.2215552 L15.6212105,14.2215552 L9,2.6665416 L2.37878954,14.2215552 Z"
      transform="translate(3 4)"
    />
  </Icon>
);

export default IconAlertWarning;
