import { BackLocationDescriptor, NavigationTarget } from 'common/lib/Routing/types';
import { SERVER } from 'common/lib/UniversalTools/serverActionTypes';
import createReducer from 'common/util/createReducer';

export type RedirectCode = 301 | 302 | 307 | 308;

export type ServerState = {
  redirectCode: RedirectCode | null;
  redirectLocationDescriptor?: NavigationTarget;
  backLocationDescriptor: BackLocationDescriptor;
  notFound: boolean;
  gone: boolean;
};

export const initialState: ServerState = {
  redirectCode: null,
  redirectLocationDescriptor: undefined,
  backLocationDescriptor: {},
  notFound: false,
  gone: false,
};

export default createReducer<ServerState>(initialState, {
  [SERVER.REDIRECT](state, action) {
    return {
      ...state,
      redirectCode: action.code,
      redirectLocationDescriptor: action.locationDescriptor,
      backLocationDescriptor: action.backLocationDescriptor || {},
    };
  },

  [SERVER.GONE](state) {
    return {
      ...state,
      gone: true,
    };
  },

  [SERVER.NOT_FOUND](state) {
    return {
      ...state,
      notFound: true,
    };
  },
});
