import Polyglot, { Phrases, TFunction } from 'common/modules/translation/lib/PolyglotCopy';
import { Locale } from 'common/util/configTypes';

export interface ITranslationService {
  t: TFunction;
  getPhrases: () => Phrases;
  getLoadedNamespaces: () => string[];
  loadNamespaces: (namespaces: string[]) => Promise<void>;
}

const createTranslationService = (
  locale: Locale,
  onLoad: (nameSpaces: string[], locale: Locale) => Promise<Phrases | null>,
  initialState?: { phrases: Phrases; loadedNamespaces: string[] }
): ITranslationService => {
  const polyglot = new Polyglot({
    locale,
    phrases: initialState?.phrases,
  });
  let loadedNamespaces: { [key: string]: Promise<void> } = (initialState?.loadedNamespaces || []).reduce(
    (curr, namespace) => ({ ...curr, [namespace]: Promise.resolve() }),
    {}
  );

  const fetch = async (namespaces: string[]) => {
    const phrases = await onLoad(namespaces, locale);
    if (phrases) {
      polyglot.extend(phrases);
    }
  };

  const loadNamespaces = async (namespaces: string[]) => {
    const filteredNamespaces = namespaces.filter((namespace) => !Object.keys(loadedNamespaces).includes(namespace));
    if (filteredNamespaces.length) {
      const fetchPromise = fetch(filteredNamespaces);
      loadedNamespaces = filteredNamespaces.reduce(
        (curr, namespace) => ({ ...curr, [namespace]: fetchPromise }),
        loadedNamespaces
      );
    }
    await Promise.all(namespaces.map((namespace) => loadedNamespaces[namespace]));
  };

  return {
    t: polyglot.t.bind(polyglot),
    getPhrases: polyglot.getPhrases.bind(polyglot),
    loadNamespaces,
    getLoadedNamespaces: () => Object.keys(loadedNamespaces),
  };
};

export default createTranslationService;
