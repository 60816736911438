import { CookieOptions } from 'express';

import { allowedCookies } from 'common/lib/Cookies/cookieList';
import { CookieKey, ICookieService } from 'common/lib/Cookies/types';
import { readConsents } from 'common/lib/Cookies/utils/consentUtils';

class ConsentHandler implements ICookieService {
  cookieService: ICookieService;

  constructor(cookieService: ICookieService) {
    this.cookieService = cookieService;
  }

  getAll(): string | undefined {
    return this.cookieService.getAll?.();
  }

  get(key: CookieKey): string | undefined {
    return this.cookieService.get(key);
  }

  remove(key: CookieKey, options?: CookieOptions): void {
    this.cookieService.remove(key, options);
  }

  set(key: CookieKey, value: string, options?: CookieOptions, override?: boolean): void {
    if (allowedCookies(readConsents(this.cookieService.get('CONSENTMGR'))).includes(key)) {
      // jsCookie and express have not the same definition - thanks for nothing - have them both to milliseconds
      let expires: Date | undefined;
      if (typeof options?.expires === 'number') {
        expires = new Date();
        expires.setMilliseconds(expires.getMilliseconds() + options?.expires * 1000);
      }
      this.cookieService.set(key, value, { ...options, expires }, override);
    } else {
      this.cookieService.remove(key);
    }
  }
}

export default ConsentHandler;
