import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const loadingBlockFn = /* @__PURE__ */ createRecipe('loadingBlock', {}, [])

const loadingBlockVariantMap = {
  "loadingBlockType": [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p1",
    "p2"
  ]
}

const loadingBlockVariantKeys = Object.keys(loadingBlockVariantMap)

export const loadingBlock = /* @__PURE__ */ Object.assign(memo(loadingBlockFn.recipeFn), {
  __recipe__: true,
  __name__: 'loadingBlock',
  __getCompoundVariantCss__: loadingBlockFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: loadingBlockVariantKeys,
  variantMap: loadingBlockVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, loadingBlockVariantKeys)
  },
  getVariantProps: loadingBlockFn.getVariantProps,
})