/* global dataLayer */

export interface PageTrackSearchParams {
  resultCount?: number;
  resultId?: string;
  term?: string;
  location?: string;
  regionId?: number;
  categoryId?: number[];
  industryId?: number[];
  workload?: {
    min: number;
    max: number;
  };
  employmentType?: number[];
}

const trackGtm = (payload: unknown) => {
  // track only on client
  if (typeof window !== 'undefined') {
    let dl;

    // Get the GTM object from global or window.
    if (typeof dataLayer !== 'undefined') {
      dl = dataLayer;
    } else if (typeof window.dataLayer !== 'undefined') {
      dl = window.dataLayer;
    }

    if (dl) {
      dl.push(payload);
    } else {
      window.dataLayer = [payload];
    }
  }
};

export default trackGtm;
