import { combineReducers } from 'redux';

import ogpMetaPageReducer, { State as MetaState } from 'common/modules/ogp/reducers/ogpMetaPageReducer';

export interface State {
  meta: MetaState;
}

export default combineReducers<State>({
  meta: ogpMetaPageReducer,
});
