/* eslint-disable no-console */
/**
 * Helper function to get performance metrics
 * https://web.dev/navigation-and-resource-timing/
 * https://developer.mozilla.org/en-US/docs/Web/Performance/Navigation_and_resource_timings
 *
 * To enable results in the console add web-performance=true in URL
 * Example: https://www.jobs.ch/en/?web-performance=true
 *
 * For detailed table data add web-performance-table=true in URL
 * Example: https://www.jobs.ch/en/?web-performance=true&web-performance-table=true
 * Please note that table output is more demanding
 */

export const measurePerformance = () => {
  // Do not output performance metrics if
  // web-performance=true is not added to the URL
  if (typeof window !== 'undefined') {
    try {
      const enableMetrics = new URLSearchParams(window.location.search).get('web-performance');
      const tableData = new URLSearchParams(window.location.search).get('web-performance-table');
      if (!enableMetrics) return;

      const observer = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
          // Display each reported measurement in console
          if (console) {
            // If table data is enabled, output detail
            tableData
              ? console.table(entry)
              : console.log(
                  'Name: ' +
                    entry.name +
                    ', Type: ' +
                    entry.entryType +
                    ', Start: ' +
                    entry.startTime +
                    ', Duration: ' +
                    entry.duration +
                    '\n'
                );
          }
        });
      });
      observer.observe({ entryTypes: ['resource', 'mark', 'measure'] });
      performance.mark('registered-observer');
    } catch (error) {
      // noop
    }
  }
};

type SchedulerPriority = 'background' | 'user-visible' | 'user-blocking';

export const pushToNewTask = <T>(priority: SchedulerPriority = 'background', func?: () => T): Promise<T> => {
  const hasScheduler = typeof window !== 'undefined' && typeof (window as any).scheduler !== 'undefined';

  if (!!func) {
    if (hasScheduler) {
      return (window as any).scheduler.postTask(func, { priority });
    } else {
      return new Promise((resolve) => setTimeout(() => resolve(func()), 0));
    }
  }
  return new Promise((resolve) => {
    if (hasScheduler) {
      return (window as any).scheduler.postTask(resolve, { priority });
    } else {
      setTimeout(resolve, 0);
    }
  });
};
