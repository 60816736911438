import { HTMLStyledProps, styled } from 'panda/jsx';
import { forwardRef, MouseEvent } from 'react';

export interface InteractiveDivProps extends Omit<HTMLStyledProps<'div'>, 'css' | 'onClick'> {
  onClick?: (event: MouseEvent) => void;
  disabled?: boolean;
}

export interface InteractiveSpanProps extends Omit<HTMLStyledProps<'span'>, 'css' | 'onClick'> {
  onClick?: (event: MouseEvent) => void;
  disabled?: boolean;
}

export const InteractiveDiv = forwardRef<HTMLDivElement, InteractiveDivProps>(({ onClick, disabled, ...rest }, ref) => (
  <styled.div
    cursor={disabled ? 'not-allowed' : 'pointer'}
    onClick={(event) => {
      !disabled && onClick?.(event);
    }}
    onKeyDown={(event) => {
      if (event.code === 'Enter' || event.code === 'Space') {
        if (event.code === 'Space') event.preventDefault();
        !disabled && onClick?.(event as unknown as MouseEvent);
      }
    }}
    ref={ref}
    tabIndex={disabled ? -1 : 0}
    {...(!disabled ? { role: 'button' } : {})}
    {...rest}
  />
));

InteractiveDiv.displayName = 'InteractiveDiv';

export const InteractiveSpan = forwardRef<HTMLSpanElement, InteractiveSpanProps>(
  ({ onClick, disabled, ...rest }, ref) => (
    <styled.span
      cursor={disabled ? 'not-allowed' : 'pointer'}
      onClick={(event) => {
        !disabled && onClick?.(event);
      }}
      onKeyDown={(event) => {
        if (event.code === 'Enter' || event.code === 'Space') {
          if (event.code === 'Space') event.preventDefault();
          !disabled && onClick?.(event as unknown as MouseEvent);
        }
      }}
      ref={ref}
      tabIndex={disabled ? -1 : 0}
      {...(!disabled ? { role: 'button' } : {})}
      {...rest}
    />
  )
);

InteractiveSpan.displayName = 'InteractiveSpan';
