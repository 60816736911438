import { cx } from 'panda/css';
import { styled } from 'panda/jsx';
import { button, ButtonVariantProps } from 'panda/recipes';
import { forwardRef } from 'react';

import { HTMLStyledProps } from 'panda/types';

export interface GeneralButtonProps {
  buttonColor?: 'brand' | 'destructive' | 'white';
}

export interface ButtonProps
  extends GeneralButtonProps,
    ButtonVariantProps,
    Omit<HTMLStyledProps<'button'>, 'css' | 'title'> {}

interface ButtonInLinkProps
  extends GeneralButtonProps,
    ButtonVariantProps,
    Omit<HTMLStyledProps<'span'>, 'css' | 'title'> {
  disabled?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ buttonColor = 'brand', buttonSize, buttonType, className, ...rest }, ref) => (
    <styled.button
      className={cx(button({ buttonSize, buttonType }), className)}
      colorPalette={`button.${buttonColor}`}
      ref={ref}
      type="button"
      {...rest}
    />
  )
);

Button.displayName = 'Button';

export const ButtonInLink = forwardRef<HTMLSpanElement, ButtonInLinkProps>(
  ({ buttonColor = 'brand', buttonSize, buttonType, className, ...rest }, ref) => (
    <styled.span
      className={cx(button({ buttonSize, buttonType }), className)}
      colorPalette={`button.${buttonColor}`}
      ref={ref}
      tabIndex={-1}
      {...rest}
    />
  )
);

ButtonInLink.displayName = 'ButtonInLink';
