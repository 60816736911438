import { styled } from 'panda/jsx';

export const Divider = styled('hr', {
  base: {
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderTop: 'b10',
    mb: 's0',
    mt: 's0',
  },
});
