import createReducer from 'common/util/createReducer';

import { SEARCH } from 'common/modules/search/actions/actionTypes';

import { Matches } from 'common/modules/search/types';

export default createReducer(
  {},
  {
    [SEARCH.MATCHES.ADD](state: Matches, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  }
);
