import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist';

import runClientCore from 'common/lib/Client/core';
import ClientCookieService from 'common/lib/Cookies/classes/ClientCookieService';
import ConsentHandler from 'common/lib/Cookies/classes/ConsentHandler';
import { getDiInstance } from 'common/lib/DependencyInjection/services/services';
import FetchService from 'common/lib/Fetch/services/FetchService';
import { createLocationService } from 'common/lib/Routing/services/LocationService';
import { initSentry } from 'common/lib/UniversalTools/sentry';
import { locale, tenant } from 'common/util/configHandler';
import { Tenant } from 'common/util/configTypes';
import { measurePerformance } from 'common/util/webVitals';

import { setPersisted } from 'common/modules/redux-persist/actions/reduxPersistActions';

import { AuthService } from 'common/modules/auth/services/AuthService';
import { createReactQueryClient } from 'common/modules/react-query/reactQueryClient';
import { createSeoServiceClient } from 'common/modules/seo/services/SeoServiceClient';
import createTranslationService from 'common/modules/translation/factories/TranslationServiceClient';
import { ReduxStore } from 'common/stores/global';
import configureStore from 'common/stores/index';

// Measure performance using Web Vitals
measurePerformance();

tenant.set(__TENANT__ as Tenant);
locale.set(window.__LOCALE__);

const di = getDiInstance();
di.set('core.locale', () => window.__LOCALE__);
di.set('core.cookie', () => new ConsentHandler(new ClientCookieService()));

initSentry(di.get('core.config'), {
  clientClassification: window.__INIT__.botDetect?.clientClassification,
  environment: __UNITY_ENV__,
  origin: 'client',
  sessionId: di.get('core.cookie').get('session_id'),
  tenant: __TENANT__ as Tenant,
});

const translationService = createTranslationService(di, window.__TRANSLATIONS__);

const getCustomRoutes = (() => {
  if (__TENANT__ === 'jobs') {
    return {
      de: () => import(/* webpackChunkName: "jobs-core-routes-de" */ 'jobs/routes/de'),
      en: () => import(/* webpackChunkName: "jobs-core-routes-en" */ 'jobs/routes/en'),
      fr: () => import(/* webpackChunkName: "jobs-core-routes-fr" */ 'jobs/routes/fr'),
    }[di.get('core.locale')];
  } else {
    return {
      de: () => import(/* webpackChunkName: "jobup-core-routes-de" */ 'jobup/routes/de'),
      en: () => import(/* webpackChunkName: "jobup-core-routes-en" */ 'jobup/routes/en'),
      fr: () => import(/* webpackChunkName: "jobup-core-routes-fr" */ 'jobup/routes/fr'),
    }[di.get('core.locale')];
  }
})();

getCustomRoutes().then(({ default: customRoutes }) => {
  di.set('core.translation', () => translationService);

  const browserHistory = createBrowserHistory();
  di.set('core.location', () => createLocationService());
  di.get('core.location').setLocation(browserHistory.location);

  const store: ReduxStore = configureStore({
    initialState: window.__INIT__,
    history: browserHistory,
    di,
  });

  di.set('core.redux', () => store);

  di.set(
    'auth.service',
    () =>
      new AuthService(
        di.get('core.cookie'),
        di.get('core.locale'),
        di.get('core.location'),
        di.get('core.redux'),
        di.get('core.tealium')
      )
  );

  di.set(
    'core.fetch',
    (container) =>
      new FetchService({
        authService: container.get('auth.service'),
        cookieService: container.get('core.cookie'),
        defaultHeaders: {
          'x-node-request': 'false',
          'x-source': window.__INIT__.mobileDetect.isMobile ? `${__TENANT__}_ch_mobile` : `${__TENANT__}_ch_desktop`,
        },
        locale: container.get('core.locale'),
      })
  );

  di.set('seo.meta', (container) => createSeoServiceClient(window, container.get('core.locale')));

  di.set('query.client', createReactQueryClient);

  persistStore(store, null, () => store.dispatch(setPersisted(['localFlags'])));

  browserHistory.listen(() =>
    store.dispatch({
      type: 'LOCATION_CHANGE',
    })
  );

  runClientCore({
    store,
    browserHistory,
    routes: customRoutes,
    di,
  });
});
