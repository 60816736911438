import { QueryCompat } from 'common/lib/Routing/types';

export const urlSearchParamsToObject = (urlSearchParams: URLSearchParams): QueryCompat =>
  [...urlSearchParams.entries()].reduce<QueryCompat>((acc, [key, val]) => {
    if ({}.hasOwnProperty.call(acc, key)) {
      const currentValue = acc[key];
      // if the current key is already an array, we'll add the value to it
      if (Array.isArray(currentValue)) {
        acc[key] = [...currentValue, val];
      } else {
        // if it's not an array, but contains a value, we'll convert it into an array
        // and add the current value to it
        acc[key] = [currentValue as string, val];
      }
    } else {
      // plain assignment if no special case is present
      acc[key] = val;
    }

    return acc;
  }, {});
