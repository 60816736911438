import { HTMLStyledProps, styled } from 'panda/jsx';
import { forwardRef, PropsWithChildren } from 'react';

import IconAdd from 'icon/IconAdd';
import IconClose from 'icon/IconClose';
import IconShowMoreItems from 'icon/IconShowMoreItems';

interface PillProps extends Omit<HTMLStyledProps<'span'>, 'css' | 'title'> {
  icon?: 'add' | 'more' | 'remove';
}

const iconMap = {
  add: IconAdd,
  more: IconShowMoreItems,
  remove: IconClose,
};

const PillWrapper = styled('span', {
  base: {
    alignItems: 'center',
    bg: { _hover: 'gray.01', base: 'white' },
    border: 'b20',
    borderRadius: 'r24',
    boxShadow: { _hover: 'bs25' },
    color: 'gray.90',
    display: 'flex',
    fontSize: 'f16',
    height: '[48px]',
    justifyContent: 'center',
    lineHeight: '1.5',
    px: 's16',
    py: 's8',
  },
});

export const Pill = forwardRef<HTMLSpanElement, PropsWithChildren<PillProps>>(({ children, icon, ...rest }, ref) => {
  const Icon = icon && iconMap[icon];

  return (
    <PillWrapper ref={ref} role="button" {...rest}>
      {children}
      {Icon && <Icon {...(!!children ? { ml: 's8' } : {})} />}
    </PillWrapper>
  );
});

Pill.displayName = 'Pill';
