import { createContext, FC, useEffect, useMemo, useRef, useState } from 'react';
import { matchRoutes, renderMatches, RouteMatch, RouteObject, useLocation, useNavigationType } from 'react-router-dom';

import useDi from 'common/lib/DependencyInjection/useDi';
import { RouteLoading } from 'common/lib/Routing/types';
import {
  executeAfterLoad,
  handleScroll,
  loadAfterTransition,
  loadBeforeTransition,
  loadComponents,
} from 'common/lib/Routing/utils/transitionUtils';
import { TrackingSourceProvider } from 'common/lib/Tracking/TrackingSourceContext';
import Unknown from 'common/lib/Validators/Unknown/Unknown';
import { pushToNewTask } from 'common/util/webVitals';

import { LoadingStateContext } from 'common/lib/Routing/contexts/loadingStateContext';
import { NavigateContext } from 'common/lib/Routing/contexts/navigateContext';
import useModal from 'common/modules/modal/hooks/useModal';

import InitCookieConsent from 'common/lib/Cookies/components/InitCookieConsent';
import LocationChangeObserver from 'common/lib/Routing/components/LocationChangeObserver';
import XpDevLoader from 'common/lib/Routing/components/XpDevLoader';

import GlobalState from 'common/stores/global';

interface Props {
  routes: RouteObject[];
  isHydrated: boolean;
  initialState?: Partial<GlobalState>;
}

export const LocationContext = createContext<any>(null);
export const RouteContext = createContext<RouteLoading>({});

const mapTrackingSource = (matches: RouteMatch[] | null) =>
  (matches || [])
    .map(({ route }) => (route as RouteLoading).component?.trackingSource)
    .filter(Boolean)
    .join('_');

const TransitionHandler: FC<Props> = ({ routes, isHydrated, initialState }) => {
  const location = useLocation();
  const state = Unknown.of(location.state);
  const noScroll = state.get('noScroll').getBoolean();
  const navigationType = useNavigationType();
  const isMounted = useRef(false);
  const [hydrated, setHydrated] = useState(isHydrated);
  const [cachedLocation, setCachedLocation] = useState(location);
  const selectedRoutes = useMemo(() => matchRoutes(routes, cachedLocation), [routes, cachedLocation]);
  const [isLoading, setIsLoading] = useState(false);
  const [navigating, setNavigating] = useState(false);
  const [trackingSource, setTrackingSource] = useState<string>(mapTrackingSource(selectedRoutes));
  const di = useDi();
  const { openModal } = useModal('new-build-info');

  useEffect(
    () => {
      const performTransition = async () => {
        if (!isMounted.current && isHydrated) {
          isMounted.current = true;
          await pushToNewTask('user-visible');
          !!selectedRoutes && (await executeAfterLoad(selectedRoutes, di));
          return; // return first time if is hydrated
        }

        const newRoutes = matchRoutes(routes, location);
        if (newRoutes) {
          di.get('core.location').setLocation(location);
          di.get('core.location').setRouteParams(newRoutes);

          setNavigating(true);
          await pushToNewTask('user-blocking');
          await loadComponents({ matches: newRoutes, di, initialState, notFoundEvent: openModal });

          await loadBeforeTransition(newRoutes, di);
          setCachedLocation(location);
          await pushToNewTask('user-blocking');

          setIsLoading(true);
          await pushToNewTask('user-blocking');
          setNavigating(false);
          setTrackingSource(mapTrackingSource(newRoutes));
          !noScroll && handleScroll(navigationType);

          await pushToNewTask('user-visible');
          await loadAfterTransition(newRoutes, di);
          setIsLoading(false);
          setHydrated(true);
          await pushToNewTask('user-visible');
          await executeAfterLoad(newRoutes, di);
        }
      };
      performTransition();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );

  if (hydrated) {
    return (
      <NavigateContext.Provider value={navigating}>
        <LocationContext.Provider value={cachedLocation}>
          <RouteContext.Provider value={selectedRoutes?.[selectedRoutes.length - 1].route as RouteLoading}>
            <LoadingStateContext.Provider value={isLoading}>
              <>
                <LocationChangeObserver />
                <InitCookieConsent />
                <TrackingSourceProvider value={trackingSource}>{renderMatches(selectedRoutes)}</TrackingSourceProvider>
              </>
            </LoadingStateContext.Provider>
          </RouteContext.Provider>
        </LocationContext.Provider>
      </NavigateContext.Provider>
    );
  }
  return <XpDevLoader />;
};

export default TransitionHandler;
