import { FC } from 'react';

import { Icon, IconProps } from 'common/ui/base';

const IconClose: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M12 13.4l-4.9 4.9a.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7l4.9-4.9-4.9-4.9a.948.948 0 0 1-.275-.7c0-.284.092-.517.275-.7a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275l4.9 4.9 4.9-4.9a.948.948 0 0 1 .7-.275c.283 0 .517.091.7.275a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7L13.4 12l4.9 4.9a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7.948.948 0 0 1-.7.275.948.948 0 0 1-.7-.275L12 13.4z" />
  </Icon>
);

export default IconClose;
