import { attachToDom } from 'common/modules/seo/utils/domUtils';

import { MetaLink } from 'common/modules/seo/types';

const createMetaLinkTag = ({ href, rel }: MetaLink): void => {
  const tag = document.createElement('link');
  tag.setAttribute('data-seo', 'meta-link');
  tag.href = href;
  tag.rel = rel;
  attachToDom(tag);
};

const setMetaLinks = (links: MetaLink[]): void => {
  links.forEach((link) => {
    const linkElement = document.querySelector(`link[data-seo="meta-link"][rel="${link.rel}"]`);
    if (!linkElement) {
      createMetaLinkTag(link);
    } else if (linkElement.getAttribute('href') !== link.href) {
      linkElement.setAttribute('href', link.href);
    }
  });
};

export default setMetaLinks;
