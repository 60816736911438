export type UnknownType = {
  eject: () => unknown;
  get: (key: string) => UnknownType;
  getString: () => string | undefined;
  getNumber: () => number | undefined;
  getBoolean: () => boolean | undefined;
  map: <T>(func: (arg: unknown) => T) => UnknownType;
  getArray: () => UnknownType[];
  /**
   * would use this as little as possible
   */
  getObject: () => object | undefined;
  convert: <T>(func: (arg: UnknownType) => T | undefined) => T | undefined;
};

const Unknown = (value: unknown): UnknownType => ({
  eject: () => value,
  get: (key: string): UnknownType => {
    if (value && typeof value === 'object' && key in value) {
      return Unknown.of(value[key as keyof typeof value]);
    }
    return Unknown.of(undefined);
  },
  getString: () => {
    if (typeof value === 'string') {
      return value;
    }
    return undefined;
  },
  getNumber: () => {
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value === 'string') {
      const num = Number(value);
      if (isNaN(num)) {
        return undefined;
      }
      return num;
    }
    return undefined;
  },
  getBoolean: () => {
    if (typeof value === 'boolean') {
      return value;
    }
    return undefined;
  },
  getArray: () => {
    if (!!value && Array.isArray(value)) {
      return value.map(Unknown.of);
    }
    return [];
  },
  getObject: () => {
    if (!!value && typeof value === 'object') {
      return value;
    }
    return;
  },
  convert: <T>(func: (arg: UnknownType) => T | undefined) => func(Unknown.of(value)),
  map: (func) => Unknown(func(value)),
});

Unknown.of = Unknown;

export default Unknown;
