import isPromise from 'is-promise';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';

import { PromiseAction } from 'common/lib/SideEffects/promiseActions';

import GlobalState from 'common/stores/global';

const maybeDispatch = (dispatch: Dispatch, action: PromiseAction) => action && dispatch(action);

/**
 * Maps a steps array to the outcome of a Promise.
 * - Promise.resolve => steps[0][0]
 * - Promise.reject  => steps[0][1]
 */
function applyPromises(dispatch: Dispatch, steps: any[] = [], q: Promise<any>) {
  return steps.reduce(
    (previousQ, [success = undefined, failure = undefined]) =>
      previousQ.then(
        (val: PromiseAction) => maybeDispatch(dispatch, success(val)),
        (err: PromiseAction) => maybeDispatch(dispatch, failure(err))
      ),
    q
  );
}

export const promiseMiddleware =
  ({ dispatch }: MiddlewareAPI<any, GlobalState>) =>
  (next: Dispatch<AnyAction>) =>
  (action: PromiseAction) => {
    const result = next(action);

    if (isPromise(result)) {
      return applyPromises(dispatch, action.meta?.steps, result);
    }

    return result;
  };
