import { GeneralAction } from 'common/lib/Actions/types';
import { DependencyInjection } from 'common/lib/DependencyInjection/services/services';
import { ActionType as FetchActionType } from 'common/lib/SideEffects/fetchActions';

const createFetchMiddleware = (di: DependencyInjection) => () => (next: GeneralAction) => (action: GeneralAction) => {
  if (action.type !== FetchActionType) {
    return next(action);
  }

  const { path, params = {} } = action.payload;

  const promise = di.get('core.fetch').fetch(path, params);

  // this middleware has been built in the time before async await and it is a pleasure to have them now
  return promise.then((response) => {
    if (response.ok) {
      return Promise.resolve({
        json: response.result,
        response: { ok: response.ok, status: response.statusCode, code: response.statusCode },
      });
    }
    return Promise.reject({
      json: response.result,
      response: { ok: response.ok, status: response.statusCode, code: response.statusCode },
    });
  });
};

export default createFetchMiddleware;
