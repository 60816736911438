import { HTMLStyledProps, styled } from 'panda/jsx';
import { forwardRef } from 'react';

interface BadgeTypes extends Omit<HTMLStyledProps<'span'>, 'css'> {
  badgeColor?: 'gray' | 'red';
  hasOutline?: boolean;
  badgeType?: 'dot' | 'count';
}

const StyledBadge = styled('span', {
  base: {
    alignSelf: 'center',
    bg: 'colorPalette.bg',
    borderRadius: 'r48',
    color: 'colorPalette.color',
    display: 'inline-block',
    fontSize: 'f14',
    lineHeight: '[24px]',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  variants: {
    badgeType: {
      dot: { height: '[8px]', minWidth: '[8px]' },
      count: {
        height: '[24px]',
        minWidth: '[24px]',
        px: '[6px]',
      },
    },
  },
  defaultVariants: {
    badgeType: 'count',
  },
});
export const Badge = forwardRef<HTMLSpanElement, BadgeTypes>(({ badgeColor = 'gray', hasOutline, ...rest }, ref) => (
  <StyledBadge
    colorPalette={`badge.${badgeColor}`}
    {...(hasOutline ? { outline: '[1px solid {colors.white}]' } : {})}
    ref={ref}
    {...rest}
  />
));

Badge.displayName = 'Badge';
