import { HTMLStyledProps, styled } from 'panda/jsx';
import { ellipsis } from 'panda/patterns';
import { FC, ReactElement, ReactNode } from 'react';

import { Div, Divider, Flex, HeadingVariant } from 'common/ui/base';

export interface CardProps extends Omit<HTMLStyledProps<'div'>, 'css'> {
  cardType?: 'default' | 'outline';
}

export const Card = styled('div', {
  base: {
    bg: 'white',
  },
  variants: {
    cardType: {
      default: {
        borderRadius: 'r24',
        boxShadow: 'bs25',
      },
      outline: {
        border: 'b20',
        borderRadius: 'r16',
      },
    },
  },
  defaultVariants: {
    cardType: 'default',
  },
});

interface CardHeaderProps extends Omit<HTMLStyledProps<'div'>, 'css'> {
  action?: ReactNode;
  hasEllipsis?: boolean;
  // icon?: ReactElement<typeof Icon>;
  icon?: ReactElement;
  noDivider?: boolean;
  title: string;
  titleId?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4';
}

export const CardHeader: FC<CardHeaderProps> = ({
  action,
  hasEllipsis = false,
  icon,
  noDivider,
  textStyle = 'h3',
  title,
  titleId,
  variant = 'h3',
  ...rest
}) => (
  <>
    <Flex gap="s8" px={{ base: 's16', sm: 's24' }} py={{ base: 's8', sm: 's16' }} {...rest}>
      <Flex alignItems="center" py="s8" width="100%">
        {!!icon && (
          <Div lineHeight="24px" mr="s8">
            {icon}
          </Div>
        )}
        <HeadingVariant
          {...(hasEllipsis ? { className: ellipsis() } : {})}
          id={titleId}
          textStyle={textStyle}
          variant={variant}
          width="100%"
        >
          {title}
        </HeadingVariant>
      </Flex>
      {!!action && <>{action}</>}
    </Flex>
    {!noDivider && <Divider />}
  </>
);

export default CardHeader;
