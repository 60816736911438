import JsCookie, { CookieAttributes } from 'js-cookie';

import { ICookieService } from 'common/lib/Cookies/types';
import { config } from 'common/util/configHandler';

const securityDefaults: CookieAttributes = {
  sameSite: 'Lax',
  secure: config.COOKIE_SECURE,
  domain: config.COOKIE_DOMAIN,
};

export type ClientCookieOptions = Omit<CookieAttributes, 'sameSite' | 'secure'>;

export default class ClientCookieService implements ICookieService {
  set(key: string, value: string, options: ClientCookieOptions = {}) {
    JsCookie.set(key, value, { ...options, ...securityDefaults });
  }

  remove(key: string, options: ClientCookieOptions = {}) {
    JsCookie.remove(key, { domain: securityDefaults.domain, ...options });
  }

  get(key: string) {
    return JsCookie.get(key);
  }
}
