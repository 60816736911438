import { Dispatch } from 'redux';

import { GetState, PayloadAction } from 'common/lib/Actions/types';

import { ModalState } from 'common/modules/modal/types';

import { MODAL } from 'common/modules/modal/modalActionTypes';

interface ModalOpen extends Omit<ModalState, 'opener' | 'position'> {
  openClean?: boolean;
}

export const open = (payload: ModalOpen): PayloadAction<ModalOpen> => ({
  type: MODAL.OPEN,
  payload,
});

export const close =
  (closeAll = false) =>
  (dispatch: Dispatch, getState: GetState): PayloadAction<boolean | undefined> => {
    const modal = getState().modal;
    if ((!modal?.opener || closeAll) && !!modal?.triggerId) {
      setTimeout(() => document.getElementById(modal.triggerId || '')?.focus?.(), 0);
    }
    return dispatch({
      type: MODAL.CLOSE,
      payload: closeAll,
    });
  };
