import { HTMLStyledProps, styled } from 'panda/jsx';
import { forwardRef } from 'react';

const StyledH1 = styled('h1');
export const H1 = forwardRef<HTMLHeadingElement, Omit<HTMLStyledProps<'h1'>, 'css'>>(
  ({ textStyle = 'h1', ...rest }, ref) => <StyledH1 textStyle={textStyle} {...rest} ref={ref} />
);
H1.displayName = 'H1';

const StyledH2 = styled('h2');
export const H2 = forwardRef<HTMLHeadingElement, Omit<HTMLStyledProps<'h2'>, 'css'>>(
  ({ textStyle = 'h2', ...rest }, ref) => <StyledH2 textStyle={textStyle} {...rest} ref={ref} />
);
H2.displayName = 'H2';

const StyledH3 = styled('h3');
export const H3 = forwardRef<HTMLHeadingElement, Omit<HTMLStyledProps<'h3'>, 'css'>>(
  ({ textStyle = 'h3', ...rest }, ref) => <StyledH3 textStyle={textStyle} {...rest} ref={ref} />
);
H3.displayName = 'H3';

const StyledH4 = styled('h4');
export const H4 = forwardRef<HTMLHeadingElement, Omit<HTMLStyledProps<'h4'>, 'css'>>(
  ({ textStyle = 'h4', ...rest }, ref) => <StyledH4 textStyle={textStyle} {...rest} ref={ref} />
);
H4.displayName = 'H4';

const StyledH5 = styled('h5');
export const H5 = forwardRef<HTMLHeadingElement, Omit<HTMLStyledProps<'h5'>, 'css'>>(
  ({ textStyle = 'h5', ...rest }, ref) => <StyledH5 textStyle={textStyle} {...rest} ref={ref} />
);
H5.displayName = 'H5';

const StyledH6 = styled('h6');
export const H6 = forwardRef<HTMLHeadingElement, Omit<HTMLStyledProps<'h6'>, 'css'>>(
  ({ textStyle = 'h6', ...rest }, ref) => <StyledH6 textStyle={textStyle} {...rest} ref={ref} />
);
H6.displayName = 'H6';

export interface HeadingProps extends Omit<HTMLStyledProps<'h1'>, 'css'> {
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}
export const HeadingVariant = forwardRef<HTMLHeadingElement, HeadingProps>(({ variant, ...rest }, ref) => {
  switch (variant) {
    case 'h2':
      return <H2 {...rest} ref={ref} />;
    case 'h3':
      return <H3 {...rest} ref={ref} />;
    case 'h4':
      return <H4 {...rest} ref={ref} />;
    case 'h5':
      return <H5 {...rest} ref={ref} />;
    case 'h6':
      return <H6 {...rest} ref={ref} />;
    default:
      return <H1 {...rest} ref={ref} />;
  }
});
HeadingVariant.displayName = 'HeadingVariant';

const StyledLabel = styled('label');
export const Label = forwardRef<HTMLLabelElement, Omit<HTMLStyledProps<'label'>, 'css'>>(
  ({ textStyle = 'p1', ...rest }, ref) => <StyledLabel textStyle={textStyle} {...rest} ref={ref} />
);
Label.displayName = 'Label';

const StyledP = styled('p', { base: { mb: { base: 's12', _lastOfType: 's0' } } });
export const P = forwardRef<HTMLParagraphElement, Omit<HTMLStyledProps<'p'>, 'css'>>(
  ({ textStyle = 'p1', ...rest }, ref) => <StyledP textStyle={textStyle} {...rest} ref={ref} />
);
P.displayName = 'P';

export const Span = styled('span');
