import { PayloadAction } from 'common/lib/Actions/types';
import createReducer from 'common/util/createReducer';

import { REDUX_PERSIST } from 'common/modules/redux-persist/reduxPersistActionTypes';

// types
export type State = string[];

const initialState: State = [];

export default createReducer(initialState, {
  [REDUX_PERSIST.PERSISTED](state, action: PayloadAction<string[]>) {
    return [...state, ...action.payload];
  },
});
