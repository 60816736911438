import { combineReducers } from 'redux';

import matches from 'common/modules/search/reducers/matches/matchesReducer';

import { Matches } from 'common/modules/search/types';

export interface State {
  matches: Matches;
}

export default combineReducers({
  matches,
});
