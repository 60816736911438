import { ConsentCategory } from 'common/lib/Cookies/types';

const categoryConsents = {
  analytics: 'c1:1',
  search: 'c4:1',
};

export const readConsentByCategory = (cookie: string, category: ConsentCategory): boolean =>
  cookie.includes(categoryConsents[category]);

export const readConsents = (cookie?: string): ConsentCategory[] =>
  cookie
    ? [
        ...(readConsentByCategory(cookie, 'analytics') ? ['analytics' as const] : []),
        ...(readConsentByCategory(cookie, 'search') ? ['search' as const] : []),
      ]
    : [];
