import { cx } from 'panda/css';
import { HTMLStyledProps, styled } from 'panda/jsx';
import { iconButton, IconButtonVariantProps } from 'panda/recipes';
import { forwardRef, ReactElement } from 'react';

import { Icon } from 'common/ui/base';
import { GeneralButtonProps } from 'common/ui/base/buttons/Button';

interface SpecificIconButtonProps extends IconButtonVariantProps {
  icon: ReactElement<typeof Icon>;
  title: string;
}

export interface IconButtonProps
  extends GeneralButtonProps,
    SpecificIconButtonProps,
    Omit<HTMLStyledProps<'button'>, 'css' | 'title'> {}

interface IconButtonInLinkProps
  extends GeneralButtonProps,
    SpecificIconButtonProps,
    Omit<HTMLStyledProps<'span'>, 'css' | 'title'> {}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ buttonColor = 'brand', buttonSize, buttonType, className, icon, ...rest }, ref) => (
    <styled.button
      className={cx(iconButton({ buttonSize, buttonType }), className)}
      colorPalette={`button.${buttonColor}`}
      ref={ref}
      type="button"
      {...rest}
    >
      {icon}
    </styled.button>
  )
);

IconButton.displayName = 'IconButton';

export const IconButtonInLink = forwardRef<HTMLSpanElement, IconButtonInLinkProps>(
  ({ buttonColor = 'brand', buttonSize, buttonType, className, icon, ...rest }, ref) => (
    <styled.span
      className={cx(iconButton({ buttonSize, buttonType }), className)}
      colorPalette={`button.${buttonColor}`}
      display="inline-grid"
      ref={ref}
      tabIndex={-1}
      {...rest}
    >
      {icon}
    </styled.span>
  )
);

IconButtonInLink.displayName = 'IconButtonInLink';
