import { NavigationTarget } from 'common/lib/Routing/types';
import { getFullPath } from 'common/lib/Routing/utils/pathUtils';
import { attachToDom } from 'common/modules/seo/utils/domUtils';
import { locale } from 'common/util/configHandler';

const createCanonicalTag = (href: string | NavigationTarget): void => {
  const tag = document.createElement('link');
  tag.rel = 'canonical';
  tag.href = typeof href === 'string' ? href : getFullPath(href, locale.get());
  attachToDom(tag);
};

const setCanonical = (href: string | NavigationTarget): void => {
  const canonicalElement = document.querySelector('link[rel="canonical"]');
  const url = typeof href === 'string' ? href : getFullPath(href, locale.get());

  if (!canonicalElement) {
    createCanonicalTag(url);
  } else if (canonicalElement.getAttribute('href') !== url) {
    canonicalElement.setAttribute('href', url);
  }
};

export default setCanonical;
