import createReducer from 'common/util/createReducer';

import { MINIMAL } from 'common/modules/minimal-user/actionTypes';

export interface State {
  hasUnreadSearchProfileJobs: boolean;
}

export const initialState: State = {
  hasUnreadSearchProfileJobs: false,
};

export default createReducer<State>(initialState, {
  [MINIMAL.SEARCHPROFILE.STATS.SUCCESS](state, action) {
    if (!!action.payload.unread === state.hasUnreadSearchProfileJobs) {
      return state;
    }
    return {
      ...state,
      hasUnreadSearchProfileJobs: !!action.payload.unread,
    };
  },
});
