import { cx } from 'panda/css';
import { styled } from 'panda/jsx';
import { icon, IconVariantProps } from 'panda/recipes';
import { FC, PropsWithChildren } from 'react';

import { HTMLStyledProps } from 'panda/types';

export interface IconProps extends IconVariantProps, Omit<HTMLStyledProps<'span'>, 'css' | 'height' | 'width'> {}

export const Icon: FC<PropsWithChildren<IconProps>> = ({ children, className, iconSize, ...rest }) => (
  <styled.span className={cx(icon({ iconSize }), className)} {...rest}>
    <styled.svg
      aria-hidden="true"
      fill="[currentColor]"
      focusable="false"
      height="100%"
      viewBox="0 0 24 24"
      width="100%"
    >
      {children}
    </styled.svg>
  </styled.span>
);
