import { RouteKey } from 'common/util/routeKeys';

import { PublishedOn } from '@jobcloud/job-search-api-types';

export enum Tenant {
  JOBS = 'jobs',
  JOBUP = 'jobup',
}

export enum Locale {
  DE = 'de',
  FR = 'fr',
  EN = 'en',
}

export type MultiLangRoute = { [key in Locale]: string };

export type APIEndpoint =
  | {
      CLIENT: string;
      SERVER: string;
    }
  | string;

type OneLogAPI = {
  CLIENT_ID: string;
  ENDPOINT: string;
};

export type Route = string | MultiLangRoute;

export type ConfigurationRoutes = {
  [key in RouteKey]: Route;
};

export type AppConfiguration = {
  API: APIEndpoint;
  APPLICATION_SERVICE: APIEndpoint;
  CONSULTANT_LOGO_RESOURCE: string;
  CONTACT_API: string;
  CONTENT_INJECTION_API: string;
  COOKIE_DOMAIN: string;
  COOKIE_SECURE: boolean;
  DATA_TRACKING_API: APIEndpoint;
  DATA_TRACKING_API_V2: APIEndpoint;
  DEFAULT_SEARCH_RESULTS: number;
  ENV: 'autoenv' | 'development' | 'prod' | 'spec_development' | 'spec_runner' | 'stage' | 'torai';
  GATM_CODE?: string;
  GTC_START_DATE: string;
  HOST: string;
  CLARITY_ID: string | false;
  INFO_BLOCK_API: APIEndpoint;
  INFO_LINK_API: APIEndpoint;
  INTERNAL_LINK_GEN_PROTOCOL: string;
  JOBCLOUD_HOST: string;
  JOB_SEARCH_API: APIEndpoint;
  LEGACY_COOKIE_PATH: string;
  LOCALE_ALLOWLIST: { [key in Locale]: string[] };
  LOCALELESS_PATHS: string[];
  LOCATIONIQ_MAPS_KEY?: string;
  MEDIA_API_HOST: APIEndpoint;
  METADATA_API: APIEndpoint;
  ONELOG: OneLogAPI;
  OPENAI_API_KEY?: string;
  PAGINATION_DENYLIST: string[];
  PLATFORM_TITLE: string;
  PRODUCT_HOST: string;
  PROTOCOL: string;
  RELEASE?: string;
  REVIEW_API: APIEndpoint;
  RICO_API: APIEndpoint;
  ROUTES: ConfigurationRoutes;
  SALARY_API: APIEndpoint;
  SALARY_API_NEW: APIEndpoint;
  SENTRY_DSN: string;
  SENTRY_FILE_NAME?: string;
  SENTRY_OPTIONS?: {
    allowUrls?: string[];
    release?: string;
    sampleRate?: number;
  };
  SENTRY_PUBLIC_KEY: string | false;
  SERVER?: {
    BUNYAN: {
      name: string;
      streams: [
        {
          type: string;
          path: string;
          period: string;
          count: number;
          level: string;
        }
      ];
    };
  };
  SUPPORTED_LOCALES: Locale[];
  SURVEY_API: string;
  TEALIUM_UTAG: string;
  THEME_COLOR: string;
  USE_SAFE_DEFAULTS?: boolean;
  VACANCY_COMPANY_PUBLISHED_ON: PublishedOn[] | null;
  VIMEO_THUMBNAIL_URL: string;
  VIMEO_URL: string;
  YOUTUBE_THUMBNAIL_URL: string;
  YOUTUBE_URL: string;
  USER_ACTION_API: string;
  WEBINAR_API: string;
  ZENDESK_API_KEY: string;
};
