import { ClickEvent, ClickEventType, LinkClickEvent } from 'common/modules/tealium/utils/tealiumEvents';
import { RouteKey } from 'common/util/routeKeys';

export const getGenericClickEvent = ({
  customEventData = {},
  routeKey,
}: {
  customEventData?: Partial<ClickEvent>;
  routeKey?: RouteKey;
}): ClickEvent => ({
  event_name: 'click',
  event_placement_page_category: routeKey?.toLowerCase(),
  event_placement_page_title: document.title,
  event_placement_url_full: window.location.href,
  event_position: '',
  event_type: 'generic_click',
  event_value: '',
  ...customEventData,
});

export const getLinkClickEvent = ({
  customEventData,
  routeKey,
  trigger,
}: {
  customEventData: Partial<LinkClickEvent> & { event_type: ClickEventType };
  routeKey?: RouteKey;
  trigger: HTMLAnchorElement;
}): LinkClickEvent => ({
  event_component_type: getClosestFeature(trigger),
  event_link_text: trigger.innerText || trigger?.textContent || trigger.getAttribute('title') || '',
  event_name: 'click',
  event_placement_page_category: routeKey?.toLowerCase(),
  event_placement_page_title: document.title,
  event_placement_url_full: window.location.href,
  event_position: '',
  event_target_url_full: trigger.href,
  event_value: '',
  ...customEventData,
});

export const getNavigationClickEvent = ({
  customEventData = {},
  path,
  routeKey,
  trigger,
}: {
  customEventData?: Partial<LinkClickEvent>;
  path: string;
  routeKey?: RouteKey;
  trigger?: Element;
}): LinkClickEvent => ({
  event_component_type: getClosestFeature(trigger),
  event_link_text: (trigger as HTMLElement)?.innerText || trigger?.textContent || trigger?.getAttribute('title') || '',
  event_name: 'click',
  event_placement_page_category: routeKey?.toLowerCase(),
  event_placement_page_title: document.title,
  event_placement_url_full: window.location.href,
  event_position: '',
  event_target_url_full: path,
  event_type: 'internal_link',
  event_value: '',
  ...customEventData,
});

export const getClosestFeature = (el?: Element): string => el?.closest('[data-feat]')?.getAttribute('data-feat') || '';
