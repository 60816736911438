// I shamelessly copied this from the airbnb polyglot library
// why? we do not need that much plural functions

//     (c) 2012-2018 Airbnb, Inc.
//
//     polyglot.js may be freely distributed under the terms of the BSD
//     license. For all licensing information, details, and documentation:
//     http://airbnb.github.com/polyglot.js
//
//
// Polyglot.js is an I18n helper library written in JavaScript, made to
// work both in the browser and in Node. It provides a simple solution for
// interpolation and pluralization, based off of Airbnb's
// experience adding I18n functionality to its Backbone.js and Node apps.
//
// Polylglot is agnostic to your translation backend. It doesn't perform any
// translation; it simply gives you a way to manage translated phrases from
// your client- or server-side JavaScript application.

import { config } from 'common/util/configHandler';

type Phrase = string;
export interface Phrases {
  [key: string]: Phrase;
}

const replace = String.prototype.replace;
const split = String.prototype.split;

// #### Pluralization methods
// The string that separates the different phrase possibilities.
const delimiter = '||||';

interface PluralTypes {
  [lang: string]: PluralFunction;
}

interface PluralTypesToLanguages {
  [lang: string]: string[];
}

interface PluralRules {
  pluralTypes: PluralTypes;
  pluralTypeToLanguages: PluralTypesToLanguages;
}

interface Options {
  locale?: string;
  phrases?: Phrases;
  interpolation?: { prefix?: string; suffix?: string };
  pluralRules?: PluralRules;
  allowMissing?: boolean;
  onMissingKey?: (key: string, opts: TOptions, locale: string, tokenRegex: RegExp, pluralRules: PluralRules) => string;
}

export type TOptions =
  | {
      _?: string;
      smart_count?: number;
      [key: string]: string | number | undefined;
    }
  | number;

export type TFunction = (key: string, options?: TOptions) => string;

// %{smart_count} Jobs |||| 1 Job |||| Keine Jobs

type PluralFunction = (n: number, textLength: number) => number;

const defaultPluralRules: PluralRules = {
  // Mapping from pluralization group plural logic.
  pluralTypes: {
    common: function (n, textLength) {
      if (textLength === 3 && n === 0) return 2;
      return n === 1 ? 1 : 0;
    },
  },

  // Mapping from pluralization group to individual language codes/locales.
  // Will look up based on exact match, if not found and it's a locale will parse the locale
  // for language code, and if that does not exist will default to 'en'
  pluralTypeToLanguages: {
    common: ['de', 'en', 'fr'],
  },
};

const langToTypeMap = (mapping: PluralTypesToLanguages) => {
  const ret: { [lang: string]: string } = {};
  Object.keys(mapping).forEach((languageGroup) => {
    mapping[languageGroup].forEach((lang) => {
      ret[lang] = languageGroup;
    });
  });
  return ret;
};

const langSplit = /-/;

const pluralTypeName = (pluralRules: PluralRules, locale: string): string => {
  const langToPluralType = langToTypeMap(pluralRules.pluralTypeToLanguages);
  return langToPluralType[locale] || langToPluralType[split.call(locale, langSplit, 1)[0]] || langToPluralType.en;
};

const pluralTypeIndex = (
  pluralRules: PluralRules,
  locale: string,
  count: number,
  numberOfTextVersions: number
): number => pluralRules.pluralTypes[pluralTypeName(pluralRules, locale)](count, numberOfTextVersions);

const escapeRegex = /[.*+?^${}()|[\]\\]/g;

function escape(token: string) {
  return token.replace(escapeRegex, '\\$&');
}

function constructTokenRegex(opts?: { prefix?: string; suffix?: string }): RegExp {
  const prefix = (opts && opts.prefix) || '%{';
  const suffix = (opts && opts.suffix) || '}';

  if (prefix === delimiter || suffix === delimiter) {
    throw new RangeError('"' + delimiter + '" token is reserved for pluralization');
  }

  return new RegExp(escape(prefix) + '(.*?)' + escape(suffix), 'g');
}

const defaultTokenRegex = /%{(.*?)}/g;

// Takes a phrase string and transforms it by choosing the correct
// plural form and interpolating it.
//
//     transformPhrase('Hello, %{name}!', {name: 'Spike'});
//     // "Hello, Spike!"
//
// The correct plural form is selected if substitutions.smart_count
// is set. You can pass in a number instead of an Object as `substitutions`
// as a shortcut for `smart_count`.
//
//     transformPhrase('%{smart_count} new messages |||| 1 new message', {smart_count: 1}, 'en');
//     // "1 new message"
//
//     transformPhrase('%{smart_count} new messages |||| 1 new message', {smart_count: 2}, 'en');
//     // "2 new messages"
//
//     transformPhrase('%{smart_count} new messages |||| 1 new message', 5, 'en');
//     // "5 new messages"
//
// You should pass in a third argument, the locale, to specify the correct plural type.
// It defaults to `'en'` with 2 plural forms.
function transformPhrase(
  phrase: string,
  substitutions: TOptions | undefined,
  locale: string,
  tokenRegex: RegExp,
  pluralRules: PluralRules
) {
  if (substitutions === undefined) return phrase;

  let result = phrase;
  const interpolationRegex = tokenRegex || defaultTokenRegex;
  const pluralRulesOrDefault = pluralRules || defaultPluralRules;

  // allow number as a pluralization shortcut
  const options = typeof substitutions === 'number' ? { smart_count: substitutions } : substitutions;

  // Select plural form: based on a phrase text that contains `n`
  // plural forms separated by `delimiter`, a `locale`, and a `substitutions.smart_count`,
  // choose the correct plural form. This is only done if `count` is set.
  if (options.smart_count !== undefined && result) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const texts = split.call(result, delimiter);
    result = (
      texts[pluralTypeIndex(pluralRulesOrDefault, locale || 'en', options.smart_count, texts.length)] || texts[0]
    ).trim();
  }

  // Interpolate: Creates a `RegExp` object for each interpolation placeholder.
  // this is a weird case tried to solve it with out success
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  result = replace.call(result, interpolationRegex, (expression, argument) => {
    if (!Object.prototype.hasOwnProperty.call(options, argument) || options[argument] === undefined) {
      return expression;
    }
    return options[argument];
  });

  return result;
}

class Polyglot {
  currentLocale: string;
  phrases: Phrases;
  tokenRegex: RegExp;
  pluralRules: PluralRules;
  onMissingKey:
    | null
    | ((key: string, opts: TOptions, locale: string, tokenRegex: RegExp, pluralRules: PluralRules) => string);

  constructor(options?: Options) {
    const opts: Options = options || {};
    this.phrases = {};
    this.extend(opts.phrases || {});
    this.currentLocale = opts.locale || 'en';
    const allowMissing = opts.allowMissing ? transformPhrase : null;
    this.onMissingKey = typeof opts.onMissingKey === 'function' ? opts.onMissingKey : allowMissing;
    this.tokenRegex = constructTokenRegex(opts.interpolation);
    this.pluralRules = opts.pluralRules || defaultPluralRules;
  }

  // Get or set locale. Internally, Polyglot only uses locale for pluralization.
  locale(newLocale: string) {
    if (newLocale) this.currentLocale = newLocale;
    return this.currentLocale;
  }

  // Use `extend` to tell Polyglot how to translate a given key.
  //
  //     polyglot.extend({
  //       "hello": "Hello",
  //       "hello_name": "Hello, %{name}"
  //     });
  //
  // The key can be any string.  Feel free to call `extend` multiple times;
  // it will override any phrases with the same key, but leave existing phrases
  // untouched.
  //
  // It is also possible to pass nested phrase objects, which get flattened
  // into an object with the nested keys concatenated using dot notation.
  //
  //     polyglot.extend({
  //       "nav": {
  //         "hello": "Hello",
  //         "hello_name": "Hello, %{name}",
  //         "sidebar": {
  //           "welcome": "Welcome"
  //         }
  //       }
  //     });
  //
  //     console.log(polyglot.phrases);
  //     // {
  //     //   'nav.hello': 'Hello',
  //     //   'nav.hello_name': 'Hello, %{name}',
  //     //   'nav.sidebar.welcome': 'Welcome'
  //     // }
  //
  // `extend` accepts an optional second argument, `prefix`, which can be used
  // to prefix every key in the phrases object with some string, using dot
  // notation.
  //
  //     polyglot.extend({
  //       "hello": "Hello",
  //       "hello_name": "Hello, %{name}"
  //     }, "nav");
  //
  //     console.log(polyglot.phrases);
  //     // {
  //     //   'nav.hello': 'Hello',
  //     //   'nav.hello_name': 'Hello, %{name}'
  //     // }
  //
  // This feature is used internally to support nested phrase objects.
  extend(morePhrases: Phrases) {
    this.phrases = { ...this.phrases, ...morePhrases };
  }

  // Clears all phrases. Useful for special cases, such as freeing
  // up memory if you have lots of phrases but no longer need to
  // perform any translation. Also used internally by `replace`.
  clear() {
    this.phrases = {};
  }

  // Completely replace the existing phrases with a new set of phrases.
  // Normally, just use `extend` to add more phrases, but under certain
  // circumstances, you may want to make sure no old phrases are lying around.
  replace(newPhrases: Phrases) {
    this.clear();
    this.extend(newPhrases);
  }

  // The most-used method. Provide a key, and `t` will return the
  // phrase.
  //
  //     polyglot.t("hello");
  //     => "Hello"
  //
  // The phrase value is provided first by a call to `polyglot.extend()` or
  // `polyglot.replace()`.
  //
  // Pass in an object as the second argument to perform interpolation.
  //
  //     polyglot.t("hello_name", {name: "Spike"});
  //     => "Hello, Spike"
  //
  // If you like, you can provide a default value in case the phrase is missing.
  // Use the special option key "_" to specify a default.
  //
  //     polyglot.t("i_like_to_write_in_language", {
  //       _: "I like to write in %{language}.",
  //       language: "JavaScript"
  //     });
  //     => "I like to write in JavaScript."
  //
  t: TFunction = (key, options = {}) => {
    let phrase: string | undefined;
    let result: string = key;
    if (typeof this.phrases[key] === 'string') {
      phrase = this.phrases[key];
    } else if (typeof options !== 'number' && typeof options._ === 'string') {
      phrase = options._;
    } else if (this.onMissingKey) {
      const onMissingKey = this.onMissingKey;
      result = onMissingKey(key, options, this.currentLocale, this.tokenRegex, this.pluralRules);
    } else {
      if (['development', 'spec_runner', 'spec_development', 'stage'].includes(config.ENV)) {
        throw new Error('Missing translation for key: "' + key + '"');
      }
    }

    if (typeof phrase === 'string') {
      result = transformPhrase(phrase, options, this.currentLocale, this.tokenRegex, this.pluralRules);
    }

    return result;
  };

  // Check if polyglot has a translation for given key
  has(key: string) {
    return key in this.phrases;
  }

  getPhrases(): Phrases {
    return this.phrases;
  }
}

export default Polyglot;
