import { ensureAbsolutePath } from 'common/lib/Routing/utils/pathUtils';

import { OGP } from 'common/modules/ogp/actionTypes';

export function changeOGPTitle(nextTitle: string) {
  return {
    type: OGP.CHANGE_OGP_TITLE,
    nextTitle,
  };
}

export function changeOGPDescription(nextDescription: string) {
  return {
    type: OGP.CHANGE_OGP_DESCRIPTION,
    nextDescription,
  };
}

export enum OGPType {
  Website = 'website',
  Article = 'article',
}

export function changeOGPType(nextType: OGPType) {
  return {
    type: OGP.CHANGE_OGP_TYPE,
    nextType,
  };
}

export function changeOGPUrl(ogpUrl: string) {
  return {
    type: OGP.CHANGE_OGP_URL,
    nextUrl: ensureAbsolutePath(ogpUrl),
  };
}

export function changeOGPImage(nextImage: string) {
  return {
    type: OGP.CHANGE_OGP_IMAGE,
    nextImage,
  };
}

export function changeOGPImageWidth(nextImageWidth: number) {
  return {
    type: OGP.CHANGE_OGP_IMAGE_WIDTH,
    nextImageWidth,
  };
}

export function changeOGPImageHeight(nextImageHeight: number) {
  return {
    type: OGP.CHANGE_OGP_IMAGE_HEIGHT,
    nextImageHeight,
  };
}
