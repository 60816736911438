import createReducer from 'common/util/createReducer';

import { TRACKING } from 'common/modules/tracking/actionTypes';

export interface State {
  contentTags?: string[];
  searchId: string;
}

const initialState: State = {
  searchId: '',
};

export default createReducer<State>(initialState, {
  [TRACKING.REMOVE_CONTENT_TAGS]: ({ contentTags, ...state }) => state,
  [TRACKING.SET_CONTENT_TAGS]: (state, action) => ({ ...state, contentTags: action.payload }),
  [TRACKING.SET_SEARCH_ID]: (state, action) => ({ ...state, searchId: action.payload }),
});
