import { FC } from 'react';

import { Span } from 'common/ui/base';

import { HTMLStyledProps } from 'panda/types';

interface SpinnerProps extends Omit<HTMLStyledProps<'span'>, 'css'> {
  spinnerSize?: 'lg' | 'md' | 'sm' | 'xs';
}

const spinnerSizeMap = {
  lg: { height: 50, width: 50 },
  md: { height: 28, width: 28 },
  sm: { height: 18, width: 18 },
  xs: { height: 12, width: 12 },
};

export const Spinner: FC<SpinnerProps> = ({ spinnerSize, ...rest }) => (
  <Span {...rest}>
    <svg
      style={{
        animationName: 'spinnerSpin',
        animationDuration: '1.2s',
        animationTimingFunction: 'linear',
        animationIterationCount: 'infinite',
        isolation: 'isolate',
      }}
      {...spinnerSizeMap[spinnerSize || 'lg']}
      viewBox="0 0 50 50"
    >
      <circle
        cx="25px"
        cy="25px"
        fill="none"
        r="20px"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={5}
        style={{
          animationDuration: '1.5s',
          animationIterationCount: 'infinite',
          animationName: 'spinnerDrag',
          animationTimingFunction: 'ease-in-out',
        }}
      />
    </svg>
  </Span>
);
