import { LIST } from 'common/lib/Tracking/GoogleTagManager/actionTypes';
import { DetailedPayload } from 'common/lib/Tracking/gtmTrackingActions';

export const addGtmInitialEvent = (event: DetailedPayload) => ({
  type: LIST.ADD_GTM_INITIAL_EVENT,
  payload: event,
});

export const resetGtmInitialEvent = () => ({
  type: LIST.RESET_GTM_INITIAL_EVENTS,
});
