import { FC, PropsWithChildren, useEffect, useState } from 'react';

import useTranslation from 'common/modules/i18n/hooks/useTranslation';

import { Button, ButtonProps, Span, Spinner } from 'common/ui/base';

import IconAlertWarning from 'icon/IconAlertWarning';
import IconCheckmark from 'icon/IconCheckmark';

export type ButtonState = 'default' | 'error' | 'pending' | 'success';

interface StateButtonTypes extends ButtonProps {
  buttonState?: ButtonState;
}

const ButtonContentContainer: FC<PropsWithChildren<{ hasState: boolean }>> = ({ children, hasState }) => (
  <Span
    alignItems="center"
    display="flex"
    justifyContent="center"
    position="relative"
    transform={hasState ? 'translateY(-100%)' : 'translateY(0)'}
    transitionDuration="d125"
    transitionProperty="transform"
    transitionTimingFunction="ease-out"
    willChange="transform"
  >
    {children}
  </Span>
);

const ButtonStateWrapper: FC<PropsWithChildren<{ isActive: boolean }>> = ({ children, isActive }) => (
  <Span
    alignItems="center"
    display={isActive ? 'flex' : 'none'}
    height="100%"
    justifyContent="center"
    position="absolute"
    transform="translateY(100%)"
    width="100%"
  >
    {children}
  </Span>
);

export const StateButton: FC<PropsWithChildren<StateButtonTypes>> = ({
  buttonColor = 'brand',
  buttonState = 'default',
  children,
  ...rest
}) => {
  const [state, setState] = useState<ButtonState>(buttonState);
  const t = useTranslation();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    setState(buttonState);
    if (['error', 'success'].includes(buttonState)) {
      timeout = setTimeout(() => {
        setState('default');
      }, 1500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [buttonState]);

  return (
    <Button
      {...(state === 'pending' && { 'aria-label': t('accessibility.action.loading') })}
      {...(state === 'success' && { 'aria-label': t('accessibility.action.loaded') })}
      {...(state === 'error' && { 'aria-label': t('accessibility.action.load.error') })}
      aria-live="polite"
      buttonColor={state === 'error' ? 'destructive' : buttonColor}
      {...rest}
    >
      <ButtonContentContainer hasState={state !== 'default'}>
        {children}
        <ButtonStateWrapper isActive={state === 'pending'}>
          <Spinner spinnerSize="md" />
        </ButtonStateWrapper>
        <ButtonStateWrapper isActive={state === 'success'}>
          <IconCheckmark />
        </ButtonStateWrapper>
        <ButtonStateWrapper isActive={state === 'error'}>
          <IconAlertWarning data-cy="icon-alert" />
        </ButtonStateWrapper>
      </ButtonContentContainer>
    </Button>
  );
};
