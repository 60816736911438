import { createDataLayerScript, createGTMSnippet } from 'common/lib/Tracking/GoogleTagManager/GoogleTagManagerSnippets';
import { SnippetsProps } from 'common/lib/Tracking/GoogleTagManager/types';

export const hasScript = (url = 'https://www.googletagmanager.com/gtm.js'): boolean => {
  const scripts =
    typeof window !== 'undefined' && typeof window.document !== 'undefined'
      ? Array.from(document.getElementsByTagName('script'))
      : [];

  return scripts.some((script) => script.src.startsWith(url));
};

export const removeScript = (url = 'https://www.googletagmanager.com/gtm.js') => {
  if (hasScript()) {
    const scripts = Array.from(document?.getElementsByTagName('script'));
    const scriptIncluded = scripts.find((script) => script.src.startsWith(url));
    const scriptSnippetIncluded = scripts.find((script) => script.id === 'gtm-snippet');

    scriptIncluded?.remove();
    scriptSnippetIncluded?.remove();
  }
};

const loadScripts = ({ initialEvents, id }: SnippetsProps) => {
  if (hasScript()) {
    return;
  }

  const dataLayerScript = createDataLayerScript(initialEvents);
  const script = createGTMSnippet(id);

  document.head.insertBefore(script, document.head.childNodes[0]);
  document.head.insertBefore(dataLayerScript, document.head.childNodes[0]);
};

export default loadScripts;
