import { History } from 'history';

import { GeneralAction } from 'common/lib/Actions/types';
import ILocationService from 'common/lib/Routing/services/LocationService';
import { getFullPath } from 'common/lib/Routing/utils/pathUtils';
import { HistoryAction } from 'common/lib/SideEffects//historyActions';
import { ActionVerbReplace, HISTORY } from 'common/lib/SideEffects/historyActionTypes';
import { Locale } from 'common/util/configTypes';

const historyMiddleware =
  (history: History, locationService: ILocationService, locale: Locale) =>
  () =>
  (next: GeneralAction) =>
  (action: HistoryAction) => {
    if (action.type !== HISTORY.CHANGE) {
      return next(action);
    }

    const existingQuery = action.options?.appendQueryParameters ? locationService.getQuery() : {};
    const pathReplacement =
      !('route' in action.locationDescriptor) && !('pathname' in action.locationDescriptor)
        ? {
            pathname: locationService.getLocation().pathname,
          }
        : {};
    const path = getFullPath(
      {
        ...pathReplacement,
        ...action.locationDescriptor,
        query: { ...existingQuery, ...action.locationDescriptor.query },
      },
      locale
    );

    if (path.startsWith('http') && typeof window !== 'undefined') {
      window.location.href = path;
      return null;
    }

    if (action.verb === ActionVerbReplace) {
      history.replace(path);
    } else {
      history.push(path);
    }

    return next(action);
  };

export default historyMiddleware;
