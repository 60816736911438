import { Reducer } from 'redux';

type ActionHandler<stateT> = (state: stateT, action: any) => stateT;
type ActionHandlerMap<stateT> = { [id: string]: ActionHandler<stateT> };

export default function createReducer<S>(initialState: S, handlers: ActionHandlerMap<S>): Reducer<S> {
  return (state: S = initialState, action: any) => {
    const newState = state;

    const handler = handlers[action.type];

    if (!handler) {
      return newState;
    }

    return handler(newState, action);
  };
}
