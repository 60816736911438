import { Middleware } from 'redux';

import { GeneralAction } from 'common/lib/Actions/types';

import { LOCATION_CHANGE } from 'common/lib/Routing/components/LocationChangeObserver';

import { OGP } from 'common/modules/ogp/actionTypes';
import GlobalState from 'common/stores/global';

// honestly: not 100% sure if those types are correct
// if anyone has issues with them, feel free to change
const ogpMiddleware: Middleware<GlobalState, GeneralAction> = () => (next: Function) => {
  const tagBuffer: { [id: string]: HTMLElement | null } = {};

  const getTag = (id: string): HTMLElement | null => {
    if (!(id in tagBuffer)) {
      tagBuffer[id] = window.document.querySelector(`meta[property="og:${id}"]`);
    }

    return tagBuffer[id];
  };

  const setTitle = (title: string): void => {
    const titleEl = getTag('title');
    if (typeof titleEl === 'object' && titleEl && titleEl.getAttribute('content') !== title) {
      titleEl.setAttribute && titleEl.setAttribute('content', title);
    }
  };

  const setDescription = (description: string): void => {
    const descriptionEl = getTag('description');
    if (typeof descriptionEl === 'object' && descriptionEl && descriptionEl.getAttribute('content') !== description) {
      descriptionEl.setAttribute && descriptionEl.setAttribute('content', description);
    }
  };

  const setType = (type: string): void => {
    const typeEl = getTag('type');
    if (typeof typeEl === 'object' && typeEl && typeEl.getAttribute('content') !== type) {
      typeEl.setAttribute && typeEl.setAttribute('content', type);
    }
  };

  const setUrl = (url: string): void => {
    const urlEl = getTag('url');
    if (typeof urlEl === 'object' && urlEl && urlEl.getAttribute('content') !== url) {
      urlEl.setAttribute && urlEl.setAttribute('content', url);
    }
  };

  const setImage = (image: string): void => {
    const imageEl = getTag('image');
    if (typeof imageEl === 'object' && imageEl && imageEl.getAttribute('content') !== image) {
      imageEl.setAttribute && imageEl.setAttribute('content', image);
    }
  };

  return (action) => {
    if (typeof window === 'undefined' || !window.document) {
      return next(action);
    }

    if (action.type === OGP.CHANGE_OGP_TITLE) {
      setTitle(action.nextTitle);
      return next(action);
    }

    if (action.type === OGP.CHANGE_OGP_DESCRIPTION) {
      setDescription(action.nextDescription);
      return next(action);
    }

    if (action.type === OGP.CHANGE_OGP_TYPE) {
      setType(action.nextType);
      return next(action);
    }

    if (action.type === OGP.CHANGE_OGP_URL) {
      setUrl(action.nextUrl);
      return next(action);
    }

    if (action.type === OGP.CHANGE_OGP_IMAGE) {
      setImage(action.nextImage);
      return next(action);
    }

    if (action.type === LOCATION_CHANGE) {
      setTitle('');
      setDescription('');
      setType('');
      setUrl('');
      setImage('');
    }

    return next(action);
  };
};

export default ogpMiddleware;
