import { Picture } from 'common/modules/profile/types';

type ApiObject = {
  original_media_api_id?: string;
  cropped_media_api_id?: string;
};

const pictureNormalizer = (apiObject: ApiObject = {}): Picture => ({
  originalMediaApiId: apiObject.original_media_api_id,
  croppedMediaApiId: apiObject.cropped_media_api_id,
});

export default pictureNormalizer;
