import { DependencyInjection } from 'common/lib/DependencyInjection/services/services';
import { Phrases } from 'common/modules/translation/lib/PolyglotCopy';
import { Locale } from 'common/util/configTypes';

import createTranslationService from 'common/modules/translation/services/TranslationService';

const createClientTranslationService = (
  di: DependencyInjection,
  initialState: { phrases: Phrases; loadedNamespaces: string[] }
) => {
  const loadTranslations = async (namespaces: string[], locale: Locale) => {
    const fetchService = di.get('core.fetch');
    const config = di.get('core.config');
    const host = config.PROTOCOL + '://' + config.HOST;
    const url =
      host + '/fe/translations/?lang=' + locale + '&namespaces=' + namespaces.join(',') + '&r=' + config.RELEASE;
    const response = await fetchService.fetch<Phrases>(url);
    if (!response.ok) {
      return null;
    }
    return response.result;
  };

  return createTranslationService(di.get('core.locale'), loadTranslations, initialState);
};

export default createClientTranslationService;
