import { PayloadAction } from 'common/lib/Actions/types';
import { FetchOptions } from 'common/lib/Fetch/types';

export const ActionType = 'EFFECT_FETCH';

/**
 * Pure fetch function. Mimics the fetch API, but produces a javascript
 * object describing the fetch to be performed that can be executed
 * by the fetch middleware.
 *
 * <example>
 *   function createUser(user) {
 *     return bind(fetch('/user'), {
 *       method: METHOD.POST,
 *       body: user,
 *     });
 *   }
 * </example>
 */

/**
 * @deprecated
 * Please use di.get('core.fetch') instead
 */
export default function fetch<B>(path = '', params: FetchOptions<B> = {}): PayloadAction<Record<string, any>> {
  return {
    type: ActionType,
    payload: {
      path,
      params,
    },
  };
}
