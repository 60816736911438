import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { QueryCompat } from 'common/lib/Routing/types';
import { urlSearchParamsToObject } from 'common/lib/Routing/utils/urlSearchParams';

import { useLocation } from 'common/lib/Routing/hooks/useLocation';

const useQuery = (): QueryCompat => {
  const [urlSearchParams] = useSearchParams();
  return useMemo(() => urlSearchParamsToObject(urlSearchParams), [urlSearchParams]);
};

export default useQuery;

export const useQueryParam = (key: string): string | null => {
  const [searchParams] = useSearchParams();
  return useMemo(() => searchParams.get(key), [key, searchParams]);
};

export const useQueryParameter = (key: string): string | null => new URLSearchParams(useLocation().search).get(key);
