import { getPatternStyles, patternFns } from '../helpers.js';
import { css } from '../css/index.js';

const screenReaderOnlyConfig = {
transform:(props) => ({
  position: "absolute",
  width: "1px",
  height: "1px",
  p: "s0",
  overflow: "hidden",
  clip: "rect(0, 0, 0, 0)",
  whiteSpace: "nowrap",
  clipPath: "inset(50%)",
  border: 0,
  ...props
})}

export const getScreenReaderOnlyStyle = (styles = {}) => {
  const _styles = getPatternStyles(screenReaderOnlyConfig, styles)
  return screenReaderOnlyConfig.transform(_styles, patternFns)
}

export const screenReaderOnly = (styles) => css(getScreenReaderOnlyStyle(styles))
screenReaderOnly.raw = getScreenReaderOnlyStyle