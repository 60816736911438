import { Location } from 'history';

import { Params, QueryCompat } from 'common/lib/Routing/types';
import { reverseLookupRoute } from 'common/lib/Routing/utils/routeUtils';
import { urlSearchParamsToObject } from 'common/lib/Routing/utils/urlSearchParams';
import { routes } from 'common/util/configHandler';
import { Route } from 'common/util/configTypes';
import { RouteKey } from 'common/util/routeKeys';

export default interface ILocationService {
  getLocation: () => Location;
  getPreviousLocation: () => Location | undefined;
  setLocation: (location: Location) => void;
  setRouteParams: (matches: { params: Params }[]) => void;
  getSearchParams: () => URLSearchParams;
  /**
   * @description this operation is costly , so we should remove it slowly from the code
   */
  getRoute: () => Route | undefined;
  /**
   * @description this operation is costly , so we should remove it slowly from the code
   */
  getRouteKey: () => RouteKey | undefined;
  getQuery: () => QueryCompat;
  getQueryParam: (key: string) => string | null;
  getQueryParamArray: (key: string) => string[];
  getRouteKeyStack: () => RouteKey[];
  getRouteParams: () => Params;
}

export const createLocationService = (): ILocationService => {
  let location: Location;
  let previousLocation: Location | undefined;
  let searchParams: URLSearchParams;
  let route: Route | undefined;
  let routeKey: RouteKey | undefined;
  let routeKeyStack: RouteKey[] = [];
  let query: QueryCompat = {};
  let routeParams: Params;

  const updateLocation = (newLocation: Location) => {
    previousLocation = location;
    location = newLocation;
    searchParams = new URLSearchParams(newLocation.search);
    routeKey = location.pathname ? reverseLookupRoute(location.pathname) : undefined;
    route = routeKey && routes[routeKey];
    if (routeKey) {
      routeKeyStack = [routeKey, ...routeKeyStack];
    }
    query = urlSearchParamsToObject(searchParams);
  };

  return {
    getLocation() {
      return location;
    },
    getPreviousLocation() {
      return previousLocation;
    },
    getSearchParams() {
      return searchParams;
    },
    setLocation(newLocation: Location) {
      updateLocation(newLocation);
    },
    getRoute() {
      return route;
    },
    getRouteKey() {
      return routeKey;
    },
    getRouteKeyStack() {
      return routeKeyStack;
    },
    getQuery() {
      return query;
    },
    getQueryParam(key: string) {
      return searchParams.get(key);
    },
    getQueryParamArray(key: string) {
      return searchParams.getAll(key);
    },
    setRouteParams(matches: { params: Params }[]) {
      routeParams = matches?.[0].params || {};
    },
    getRouteParams() {
      return routeParams;
    },
  };
};
