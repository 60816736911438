import { ensureAbsolutePath, getFullPath } from 'common/lib/Routing/utils/pathUtils';
import setCanonical from 'common/modules/seo/utils/canonical';
import setDescription from 'common/modules/seo/utils/description';
import setMetaLinks from 'common/modules/seo/utils/metaLinks';
import setRobotsTag from 'common/modules/seo/utils/robot';
import { Locale } from 'common/util/configTypes';

import { ISeoService } from 'common/modules/seo/services/SeoService';

export const createSeoServiceClient = (w: Window, locale: Locale): ISeoService => ({
  setTitle: (title: string) => (w.document.title = title),

  // the following functions do not have any benefits on client
  // not sure if these implementations are needed, asked @kayFreimund
  setDescription: (description) => setDescription(description),
  setCanonical: (canonical) =>
    setCanonical(ensureAbsolutePath(typeof canonical === 'string' ? canonical : getFullPath(canonical, locale))),
  setMetaLinks: (metaLinks) => setMetaLinks(metaLinks),
  setRobotsTag: (tag: string) => setRobotsTag(tag),
  setMetaScripts: () => undefined,

  reset: () => {
    setDescription('');
    setCanonical('');
    setRobotsTag('index, follow');
    setMetaLinks([]);
  },
});
