import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import loadScripts, { removeScript } from 'common/lib/Tracking/GoogleTagManager/GoogleTagManager';
import { resetGtmInitialEvent } from 'common/lib/Tracking/GoogleTagManager/googleTagManagerActions';
import { config } from 'common/util/configHandler';

import useConsent from 'common/lib/Cookies/hooks/useConsent';
import { useCurrentUserQuery } from 'common/modules/auth/hooks/useCurrentUser';

const useGoogleTagManager = () => {
  const { data: currentUser } = useCurrentUserQuery();
  const totalDocuments = useSelector((state) => state.vacancy?.results.main?.meta.totalHits);
  const { getHasCategoryConsent } = useConsent();
  const dispatch = useDispatch();
  const initialEvents = useSelector((state) => state.lists?.gtmInitialEvents) || [];

  const initialResults = useMemo(
    () =>
      totalDocuments && {
        event: 'initial-results',
        search: {
          count: totalDocuments,
        },
      },
    [totalDocuments]
  );

  useEffect(() => {
    if (!getHasCategoryConsent('analytics') || !config.GATM_CODE) {
      removeScript(); // this is probably not needed anymore.
      return;
    }

    !!initialEvents && dispatch(resetGtmInitialEvent());

    const startEvents: Object[] = [{ userUUID: currentUser?.uuid || null }];
    initialResults && startEvents.push(initialResults);

    loadScripts({
      initialEvents: [...startEvents, ...initialEvents],
      id: config.GATM_CODE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.uuid, dispatch, getHasCategoryConsent, initialResults]);
};

export default useGoogleTagManager;
