import createReducer from 'common/util/createReducer';

import { OGPType } from 'common/modules/ogp/actions/ogp';

import { OGP } from 'common/modules/ogp/actionTypes';

export interface State {
  ogpTitle: string;
  ogpDescription: string;
  ogpType: OGPType;
  ogpUrl: string;
  ogpImage: string;
  ogpImageWidth: string;
  ogpImageHeight: string;
}

export const initialState: State = {
  ogpTitle: '',
  ogpDescription: '',
  ogpType: OGPType.Website,
  ogpUrl: '',
  ogpImage: '',
  ogpImageWidth: '',
  ogpImageHeight: '',
};

export default createReducer<State>(initialState, {
  [OGP.CHANGE_OGP_TITLE](state, action) {
    if (action.nextTitle) {
      return { ...state, ogpTitle: action.nextTitle };
    }
    return state;
  },

  [OGP.CHANGE_OGP_DESCRIPTION](state, action) {
    if (action.nextDescription) {
      return { ...state, ogpDescription: action.nextDescription };
    }
    return state;
  },

  [OGP.CHANGE_OGP_TYPE](state, action) {
    if (action.nextType) {
      return { ...state, ogpType: action.nextType };
    }
    return state;
  },

  [OGP.CHANGE_OGP_URL](state, action) {
    if (action.nextUrl) {
      return { ...state, ogpUrl: action.nextUrl };
    }
    return state;
  },

  [OGP.CHANGE_OGP_IMAGE](state, action) {
    if (action.nextImage) {
      return { ...state, ogpImage: action.nextImage };
    }
    return state;
  },

  [OGP.CHANGE_OGP_IMAGE_WIDTH](state, action) {
    if (action.nextImageWidth) {
      return { ...state, ogpImageWidth: action.nextImageWidth };
    }
    return state;
  },

  [OGP.CHANGE_OGP_IMAGE_HEIGHT](state, action) {
    if (action.nextImageHeight) {
      return { ...state, ogpImageHeight: action.nextImageHeight };
    }
    return state;
  },
});
