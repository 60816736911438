import { Dispatch } from 'redux';

import { GeneralAction } from 'common/lib/Actions/types';
import { RERUN_ACTION_TYPE } from 'common/lib/SideEffects/rerunActionTypes';
import { RerunAction } from 'common/lib/SideEffects/types';

let activeReruns: RerunAction[] = [];

const stopRerunAction = (rerunAction: RerunAction) => {
  activeReruns = activeReruns.filter((item) => item !== rerunAction);
};

export const discardAllReruns = () => {
  activeReruns = [];
};

export default function rerunMiddleware(store: { dispatch?: Dispatch }) {
  return (next: GeneralAction) => (action: RerunAction) => {
    if (action.type === RERUN_ACTION_TYPE) {
      const found = action.id && activeReruns.some((el) => el.id === action.id);
      if (!found) {
        activeReruns.push(action);
      }
      return true;
    }

    const chainAnswer = next(action);

    if (activeReruns.length) {
      activeReruns.forEach((rerunAction) => {
        if (rerunAction.rerunActionTypes.includes(action.type) && !!store.dispatch) {
          store.dispatch(rerunAction.action);
          if (!rerunAction.continuous) {
            stopRerunAction(rerunAction);
          }
        }
        if (rerunAction.discardActionTypes.includes(action.type)) {
          stopRerunAction(rerunAction);
        }
      });
    }

    return chainAnswer;
  };
}

export const getActiveReruns = () => [...activeReruns];
