import { useParams } from 'react-router-dom';

import { config } from 'common/util/configHandler';
import { Locale } from 'common/util/configTypes';

const useLocale = (): Locale => {
  const { locale, ...other } = useParams<{ locale: Locale }>();

  return (locale ||
    config.SUPPORTED_LOCALES.find((loc) => (other as { '*': string })['*']?.startsWith(loc)) ||
    config.SUPPORTED_LOCALES[0]) as Locale;
};

export default useLocale;
