import { AppConfiguration, Locale, Route, Tenant } from 'common/util/configTypes';
import { RouteKey } from 'common/util/routeKeys';

function getConfig(): AppConfiguration {
  // ATTENTION:
  // Please keep the if statements separated, so that webpack can do already a tree-shaking on this variables
  // __TEST__ and also __SERVER__. Webpack won't include the import of the config if they are in different if-
  // statements.

  // unit tests
  if (__TEST__) {
    // eslint-disable-next-line
    const buildRoutes = require('./../../../.server/modules/build-routes').default;
    // eslint-disable-next-line
    const config = require('./../../../config/jobs/_development');
    const routes = buildRoutes(config);
    config.ROUTES = routes;

    return config;
  }

  if (__SERVER__) {
    const globalAny: any = global; // Typescript hack
    return globalAny.__GLOBAL__;
  }
  if (!__SERVER__ && typeof __GLOBAL__ !== 'undefined') {
    return __GLOBAL__;
  }

  // to see typescript happy
  return {} as AppConfiguration;
}

class LocaleSetting {
  code?: Locale;
  set(code: Locale) {
    this.code = code;
  }

  /**
   * @deprecated Please use the hook useLocale instead
   */
  get(): Locale {
    if (!this.code) {
      throw new Error('Locale was read befor it was set.');
    }
    return this.code;
  }
}

class TenantSetting {
  code?: Tenant;
  set(code: Tenant) {
    this.code = code;
  }
  get(): Tenant {
    if (!this.code) {
      throw new Error('Tenant was read before it was set.');
    }
    return this.code;
  }
}

const config: AppConfiguration = getConfig();
const routes: { [key in RouteKey]: Route } = config.ROUTES;

export { config };
export { routes };

/**
 * @deprecated Please use the hook useLocale instead
 */
export const locale = new LocaleSetting();
export const tenant = new TenantSetting();
