import { FC } from 'react';

import { Icon, IconProps } from 'common/ui/base';

const IconCheckmark: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M9.55 17.575c-.133 0-.258-.02-.375-.063a.871.871 0 0 1-.325-.212L4.55 13c-.183-.183-.271-.42-.263-.713.009-.29.105-.528.288-.712a.948.948 0 0 1 .7-.275.95.95 0 0 1 .7.275L9.55 15.15l8.475-8.475c.183-.183.421-.275.713-.275.291 0 .529.092.712.275a.966.966 0 0 1 .275.712c0 .292-.092.53-.275.713l-9.2 9.2c-.1.1-.208.171-.325.212a1.099 1.099 0 0 1-.375.063z" />
  </Icon>
);

export default IconCheckmark;
