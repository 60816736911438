export type ServiceKey<T> = keyof T;
type Factory<T, S> = (container: DependencyInjectionContainer<T>) => S;
export type FactoryWithIdentifier<T, K extends ServiceKey<T>> = Factory<T, T[K]> & { identifier: K };
type ServiceCache<S> = () => S;
type ServiceMap<T> = { [key in ServiceKey<T>]: ServiceCache<T[ServiceKey<T>]> };

class DependencyInjectionContainer<T> {
  private services: Partial<ServiceMap<T>> = {};

  set<K extends ServiceKey<T>>(key: K, factory: Factory<T, T[K]>) {
    if (!!this.services[key]) return;
    this.services[key] = (() => {
      let service: T[K] | undefined;
      return () => {
        if (!service) {
          service = factory(this);
        }
        return service;
      };
    })();
  }

  get<K extends ServiceKey<T>>(key: K): T[K] {
    if (!this.services[key]) {
      throw new Error(`Service ${String(key)} is not initialized yet!`);
    }
    return (this.services[key] as ServiceCache<T[K]>)();
  }

  wire<K extends ServiceKey<T>>(factory: FactoryWithIdentifier<T, K>): T[K] {
    this.set(factory.identifier, factory);
    return this.get(factory.identifier);
  }
}

export default DependencyInjectionContainer;
