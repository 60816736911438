import createReducer from 'common/util/createReducer';

// normalizer
import pictureNormalizer from 'common/modules/profile/normalizers/pictureNormalizer';

// types
import { Picture } from 'common/modules/profile/types';

import { AUTH } from 'common/modules/auth/actionTypes';
// action types
import { MINIMAL } from 'common/modules/minimal-user/actionTypes';

export type State = Picture;

const initialState: State = {};

export default createReducer<State>(initialState, {
  [MINIMAL.PICTURE.FETCH.SUCCESS](state, action) {
    return {
      ...state,
      ...pictureNormalizer(action.payload),
    };
  },

  [MINIMAL.PICTURE.UPDATE.SUCCESS](state, action) {
    return {
      ...state,
      ...pictureNormalizer(action.payload),
    };
  },

  [MINIMAL.PICTURE.DELETE.SUCCESS](state) {
    return {
      ...state,
      ...pictureNormalizer(),
    };
  },

  [AUTH.CURRENT_USER_LOGOUT.SUCCESS]() {
    return initialState;
  },
});
