import { styled } from 'panda/jsx';

export const Li = styled('li');

export const Ol = styled('ol', {
  base: {
    counterReset: 'list-items',
    listStyleType: 'none',
    pl: 's0',
    mb: 's0',
    mt: 's0',
    '& li': { counterIncrement: 'list-items' },
    '& li::before': { content: 'counter(list-items) "."', pl: 's0', pr: 's8' },
  },
});

export const Ul = styled('ul', {
  base: { listStyleType: 'none', pl: 's0', mb: 's0', mt: 's0' },
});
