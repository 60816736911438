import { ICookieService } from 'common/lib/Cookies/types';
import { IFetchService } from 'common/lib/Fetch/types';
import ILocationService from 'common/lib/Routing/services/LocationService';
import { tenant } from 'common/util/configHandler';
import { AppConfiguration, Locale } from 'common/util/configTypes';
import { pushToNewTask } from 'common/util/webVitals';

import { queryKey as userQueryKey } from 'common/modules/auth/hooks/useCurrentUser';

import { User } from 'common/modules/auth/types';
import { ReactQueryClient } from 'common/modules/react-query/types';

import { IReduxService } from 'common/stores/global';

export interface IDataTrackingService {
  post: (body: DataTrackingEvent) => Promise<void>;
}

export type EventName =
  | 'cookie_consent_accept_all'
  | 'cookie_consent_accept_all_settings'
  | 'cookie_consent_accept_partial'
  | 'cookie_consent_view'
  | 'job_application_sent'
  | 'job_application_start'
  | 'job_hit';

export interface DataTrackingEvent {
  eventName: EventName;
  eventId?: string;
  referenceIds: {
    referenceId: string;
    referenceTarget: 'auction' | 'job';
  }[];
  trackingParameters: {
    name: 'consentCategory' | 'isFirstHit' | 'job_application_method' | 'platformState' | 'position' | 'source';
    value?: string;
  }[];
}

const getClientTimeZone = (date: Date) => {
  const timeZoneOffset = String((date.getTimezoneOffset() / 60) * -1);
  return 'GMT' + (timeZoneOffset.startsWith('-') ? '' : '+') + timeZoneOffset;
};

export const createDataTrackingService = (
  config: AppConfiguration,
  fetchService: IFetchService,
  reduxService: IReduxService,
  locationService: ILocationService,
  cookieService: ICookieService,
  locale: Locale,
  queryClient: ReactQueryClient
): IDataTrackingService => {
  const post = async (event: DataTrackingEvent) => {
    await pushToNewTask('background');
    const state = reduxService.getState();
    const isDataTrackingApiV2 = state.featureFlags.includes('data-tracking-api-v2');
    const dataTrackingEndpoint = isDataTrackingApiV2
      ? `${config.DATA_TRACKING_API_V2}event`
      : `${config.DATA_TRACKING_API}postEvent`;
    const { eventId, referenceIds, trackingParameters, ...metadata } = event;
    const isMobile = state.mobileDetect.isMobile;
    const date = new Date();
    const clientTimeZone = getClientTimeZone(date);
    const query = locationService.getQuery();
    const currentUser = queryClient.getQueryData<User>(userQueryKey);
    const urlParameters = Object.keys(query)
      .map((key) => {
        const value = query[key];
        if (Array.isArray(value)) {
          return value.map((v) => ({ name: key, value: v }));
        } else {
          return { name: key, value };
        }
      })
      .flat();

    await fetchService.fetch(dataTrackingEndpoint, {
      method: 'POST',
      body: {
        detail: {
          id: eventId,
          tenant: tenant.get(),
          metadata: {
            ...metadata,
            browserAgent: (typeof navigator !== 'undefined' && navigator.userAgent) || null,
            clientApplicationName: `${tenant.get()}_ch_${isMobile ? 'mobile' : 'desktop'}`,
            clientClassification: state.botDetect.clientClassification,
            clientFingerprint: '', // ID that is generated from client metadata ?huh
            clientTimeZone,
            cookieId: '',
            eventTime: date.toISOString(),
            language: locale,
            operatingSystem: state.mobileDetect.os,
            sessionId: cookieService.get('session_id') || null,
            uiComponentId: null,
            uiElementId: null,
            urlBase: config.PROTOCOL + '://' + config.HOST + '/',
            urlParameters,
            urlPath: locationService.getLocation().pathname,
            userId: currentUser?.uuid || state.auth.notLoggedInUser,
          },
          referenceIds,
          trackingParameters,
        },
      },
    });
  };

  return { post };
};
