import { cx } from 'panda/css';
import { HTMLStyledProps, styled } from 'panda/jsx';
import { avatarCircular, AvatarCircularVariantProps, avatarSquare, AvatarSquareVariantProps } from 'panda/recipes';
import { FC, PropsWithChildren } from 'react';

export interface AvatarCircularProps extends AvatarCircularVariantProps, Omit<HTMLStyledProps<'div'>, 'css'> {}
export interface AvatarSquareProps extends AvatarSquareVariantProps, Omit<HTMLStyledProps<'div'>, 'css'> {}

export const AvatarCircular: FC<PropsWithChildren<AvatarCircularProps>> = ({
  avatarSize,
  children,
  className,
  ...rest
}) => (
  <styled.div className={cx(avatarCircular({ avatarSize }), className)} {...rest}>
    {children}
  </styled.div>
);

export const AvatarSquare: FC<PropsWithChildren<AvatarSquareProps>> = ({
  avatarSize,
  children,
  className,
  ...rest
}) => (
  <styled.div className={cx(avatarSquare({ avatarSize }), className)} {...rest}>
    {children}
  </styled.div>
);
