import { ICookieService } from 'common/lib/Cookies/types';
import { FetchOptions, FetchResult, FetchServiceParams, Headers, IFetchService } from 'common/lib/Fetch/types';
import {
  buildPath,
  getCallOptions,
  handleResponse,
  handleSetCookieHeader,
  stringifyError,
} from 'common/lib/Fetch/utils/fetchUtils';
import { logViaSentry } from 'common/lib/UniversalTools/sentry';
import { Locale } from 'common/util/configTypes';

import { IAuthService } from 'common/modules/auth/services/AuthService';

export default class FetchService implements IFetchService {
  authService: IAuthService;
  cookieService: ICookieService;
  defaultHeaders: Headers;
  locale: Locale;

  constructor({ authService, cookieService, defaultHeaders = {}, locale }: FetchServiceParams) {
    this.authService = authService;
    this.cookieService = cookieService;
    this.defaultHeaders = defaultHeaders;
    this.locale = locale;
  }

  async fetch<S = unknown, F = unknown, B = unknown>(
    rawPath: string,
    options: FetchOptions<B> = {}
  ): Promise<FetchResult<S, F>> {
    const path = buildPath(rawPath, options);

    const callOptions = getCallOptions({
      cookieService: this.cookieService,
      options,
      defaultHeaders: this.defaultHeaders,
      locale: this.locale,
      path,
    });

    try {
      const response = await fetch(path, callOptions);

      handleSetCookieHeader(response, this.cookieService);

      return handleResponse<S, F>(response, {
        authService: this.authService,
        options,
        path,
      });
    } catch (error: any) {
      logViaSentry(error, { tags: { path } });
      return {
        ok: false,
        statusCode: 500,
        result: null,
        _serverDebugError: {
          error: stringifyError(error),
          callOptions,
        },
      };
    }
  }
}
