import { cx } from 'panda/css';
import { HTMLStyledProps, styled } from 'panda/jsx';
import { container, ContainerProperties } from 'panda/patterns';
import { forwardRef } from 'react';

interface ContainerProps extends Omit<HTMLStyledProps<'div'>, 'css'>, ContainerProperties {}
export const Container = forwardRef<HTMLDivElement, ContainerProps>(({ containerSize, className, ...rest }, ref) => (
  <Div className={cx(container({ containerSize }), className)} ref={ref} {...rest} />
));

Container.displayName = 'Container';

export const Div = styled('div');

export const Flex = styled('div', { base: { display: 'flex' } });

export const Grid = styled('div', { base: { display: 'grid' } });
