import { Middleware } from 'redux';

import { TRACK_GTM } from 'common/lib/Tracking/gtmTrackingActions';
import gtmTrack from 'common/lib/Tracking/gtmTrackingService';

const gtmTrackMiddleware: Middleware = () => (next) => (action) => {
  if (!action.payload) {
    return next(action);
  }

  switch (action.type) {
    case TRACK_GTM:
      gtmTrack(action.payload);
      break;

    default:
      return next(action);
  }
};

export default gtmTrackMiddleware;
