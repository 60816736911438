import createReducer from 'common/util/createReducer';

import { SnackbarConfiguration } from 'common/modules/snackbar/actions/snackbarActions';

import { SnackbarType } from 'common/modules/snackbar/types';

import { AUTH } from 'common/modules/auth/actionTypes';
import { SNACKBAR } from 'common/modules/snackbar/snackbarActionTypes';

export interface SnackBar<D = never> extends SnackbarConfiguration<D> {
  dismissed: boolean;
  id: string;
  snackType: SnackbarType;
  willDismiss?: boolean;
}

export type State = SnackBar<unknown>[];

export const dismissSnackbar = (state: State, id: string) =>
  state.map((snack) => (snack.id === id ? { ...snack, dismissed: true } : snack));

const dismissByType = (state: State, snackType: SnackbarType) =>
  state.map((snack) => (snack.snackType === snackType ? { ...snack, dismissed: true } : snack));

export default createReducer<State>([], {
  [SNACKBAR.ADD]: (state, action) => {
    const { type, ...others } = action;
    const existing = state.find((snack) => snack.snackType === action.snackType);
    if (existing && action.once) {
      return state;
    }
    return [...state, { ...others, dismissed: false }];
  },

  [SNACKBAR.PREPARE_DISMISS]: (state, action) =>
    state.map((snack) => (snack.id === action.id ? { ...snack, willDismiss: true } : snack)),

  [SNACKBAR.DISMISS]: (state, action) => dismissSnackbar(state, action.id),

  [SNACKBAR.DISMISS_BY_TYPE]: (state, action) => dismissByType(state, action.snackType),

  [AUTH.CURRENT_USER_LOGOUT.SUCCESS]: () => [],
});
