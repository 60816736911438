import { getPatternStyles, patternFns } from '../helpers.js';
import { css } from '../css/index.js';

const ellipsisConfig = {
transform:({ lines = 1, ...rest }) => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  hyphens: "auto",
  overflowWrap: "break-word",
  whiteSpace: "normal",
  wordWrap: "break-word",
  boxOrient: "vertical",
  lineClamp: lines,
  ...rest
})}

export const getEllipsisStyle = (styles = {}) => {
  const _styles = getPatternStyles(ellipsisConfig, styles)
  return ellipsisConfig.transform(_styles, patternFns)
}

export const ellipsis = (styles) => css(getEllipsisStyle(styles))
ellipsis.raw = getEllipsisStyle