import { MouseEvent, MouseEventHandler, useCallback } from 'react';

import useDi from 'common/lib/DependencyInjection/useDi';
import {
  getGenericClickEvent,
  getLinkClickEvent,
  getNavigationClickEvent,
} from 'common/modules/tealium/utils/clickUtils';
import { ClickEventType, LinkClickEvent } from 'common/modules/tealium/utils/tealiumEvents';

export const useTealiumGenericClick = () => {
  const di = useDi();
  const { track } = di.get('core.tealium');
  const routeKey = di.get('core.location').getRouteKey();

  return useCallback(
    (customEventData?: Partial<LinkClickEvent>) => {
      track(getGenericClickEvent({ customEventData, routeKey }));
    },
    [routeKey, track]
  );
};

export const useTealiumLinkClick = (customEventData: Partial<LinkClickEvent> & { event_type: ClickEventType }) => {
  const di = useDi();
  const { track } = di.get('core.tealium');
  const routeKey = di.get('core.location').getRouteKey();

  return useCallback(
    (onClick?: MouseEventHandler<HTMLAnchorElement>) => (event: MouseEvent<HTMLAnchorElement>) => {
      if (event.currentTarget) {
        track(getLinkClickEvent({ customEventData, routeKey, trigger: event.currentTarget }));
      }
      onClick?.(event);
    },
    [customEventData, routeKey, track]
  );
};

export const useTealiumNavigateClick = () => {
  const di = useDi();
  const { track } = di.get('core.tealium');
  const routeKey = di.get('core.location').getRouteKey();

  return useCallback(
    ({
      customEventData,
      path,
      trigger,
    }: {
      customEventData?: Partial<LinkClickEvent>;
      path: string;
      trigger?: Element;
    }) => {
      track(getNavigationClickEvent({ customEventData, path, routeKey, trigger }));
    },
    [routeKey, track]
  );
};
