import { attachToDom } from 'common/modules/seo/utils/domUtils';

const createRobotsTag = (content: string): void => {
  const tag = document.createElement('meta');
  tag.name = 'robots';
  tag.content = content;
  attachToDom(tag);
};

const setRobotsTag = (content: string): void => {
  const robotsElement = window.document.querySelector('meta[name="robots"]');
  if (typeof robotsElement !== 'object' || !robotsElement) {
    createRobotsTag(content);
  } else if (robotsElement.getAttribute('content') !== content) {
    robotsElement.setAttribute('content', content);
  }
};

export default setRobotsTag;
