import { FC } from 'react';

import { Card, Div, H3, Span, Spinner } from 'common/ui/base';

const XpDevLoader: FC = () => (
  <Div pt="s80">
    <Card
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mx="auto"
      p="s16"
      width="[400px]"
    >
      <Spinner color="brand.base" mt="s16" />
      <H3 mt="s32" textAlign="center">
        XP Development Server
      </H3>
      <Span textAlign="center">Reticulating Splines...</Span>
    </Card>
  </Div>
);

export default XpDevLoader;
